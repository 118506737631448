import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { classNames } from 'utils/HelperFunctions';
import { IoIosClose } from 'react-icons/io';

type Option = {
  value: React.Key;
  label: string;
  description?: string;
  disabled?: boolean;
};
type SelectProps = {
  options: Array<Option>;
  label?: JSX.Element;
  value?: React.Key;
  preValue?: string;
  disabled?: boolean;
  onChange: (value: Option | undefined) => void;
  className?: string;
  placeholder?: string;
  id?: string;
  textStyle?: React.CSSProperties;
  dontChangeImmediately?: boolean;
  toClearFilter?: boolean;
};
export const Select: React.FC<SelectProps> = React.memo(
  ({
    options,
    label,
    disabled = false,
    dontChangeImmediately = false,
    value,
    preValue,
    className,
    placeholder,
    onChange,
    textStyle,
    id,
    toClearFilter = false,
  }) => {
    const [selectedValue, setSelectedValue] = useState<Option | undefined>({
      value: '',
      label: '',
    });
    useEffect(() => {
      const newOption = options.find(option => option.value === value);
      if (value !== undefined && newOption) {
        setSelectedValue(newOption);
      }
    }, [value]);

    return (
      <Listbox
        value={value || selectedValue?.value}
        disabled={disabled}
        onChange={selected => {
          const valueAfterSelect =
            selected === selectedValue?.value
              ? undefined
              : options?.find(option => option?.value === selected);
          if (!dontChangeImmediately) {
            setSelectedValue(valueAfterSelect);
          }
          onChange(valueAfterSelect);
        }}
      >
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className='block text-sm font-medium text-nell-white'>
                {label}
              </Listbox.Label>
            )}
            <div className='relative'>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 z-[1]'>
                {toClearFilter ? (
                  <>
                    {selectedValue?.value === '' || selectedValue?.value === undefined ? (
                      <ChevronDownIcon
                        style={textStyle}
                        className={`h-5 w-5 text-nell-light-grey ${open ? 'rotate-180' : ''}`}
                        aria-hidden='true'
                      />
                    ) : (
                      <IoIosClose
                        onClick={() => {
                          setSelectedValue(undefined);
                          onChange(undefined);
                        }}
                        className='h-7 w-7 text-nell-light-grey cursor-pointer'
                        aria-hidden='true'
                      />
                    )}
                  </>
                ) : (
                  <ChevronDownIcon
                    style={textStyle}
                    className={`h-5 w-5 text-nell-light-grey ${open ? 'rotate-180' : ''}`}
                    aria-hidden='true'
                  />
                )}
              </span>
              <Listbox.Button
                disabled={disabled}
                className={classNames(
                  className ? className : '',
                  `bg-nell-black relative w-full ${
                    open
                      ? 'rounded-b-none border-b-0 border-nell-blue !bg-nell-black'
                      : 'border-nell-light-grey'
                  } rounded-[10px] border-2  shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none sm:text-sm`
                )}
              >
                <span
                  className='block align-middle truncate text-nell-white text-sm'
                  style={textStyle}
                >
                  {preValue ? `${preValue}: ` : ''}
                  {selectedValue?.label ? (
                    selectedValue?.label
                  ) : (
                    <span className='text-nell-white text-sm truncate'>
                      {placeholder ? placeholder : 'Select'}
                    </span>
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options
                  id={id}
                  className='absolute z-50 border-2 border-nell-blue rounded-t-none border-t-0 w-full bg-nell-black shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
                >
                  {options?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-nell-light-grey' : 'text-nell-white',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option?.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-nell-white',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  }
);
