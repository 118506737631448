import { CompanyProfile } from './Profile';
import { UsersRolesSection } from './UsersRolesSection';

export const Settings = () => {
  return (
    <div>
      <CompanyProfile />
      <UsersRolesSection />
    </div>
  );
};
