import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { classNames } from 'utils/HelperFunctions';

type Option = {
  value: React.Key;
  label: string;
};
type SelectProps = {
  options: Array<Option>;
  label?: JSX.Element;
  value?: React.Key;
  preValue?: string;
  disabled?: boolean;
  onChange: (value: Option | undefined) => void;
  className?: string;
  placeholder?: string;
  id?: string;
  textStyle?: React.CSSProperties;
};
export const CustomSelect: React.FC<SelectProps> = React.memo(
  ({
    options,
    label,
    disabled = false,
    value,
    preValue,
    className,
    placeholder,
    onChange,
    textStyle,
    id,
  }) => {
    const [selectedValue, setSelectedValue] = useState<Option | undefined>({
      value: '',
      label: '',
    });
    useEffect(() => {
      const newOption = options.find(option => option.value === value);
      if (value !== undefined && newOption) {
        setSelectedValue(newOption);
      }
    }, [value]);

    return (
      <Listbox
        value={selectedValue?.value}
        disabled={disabled}
        onChange={selected => {
          const valueAfterSelect = options?.find(option => option?.value === selected);
          setSelectedValue(valueAfterSelect);
          onChange(valueAfterSelect);
        }}
      >
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className='block text-base font-medium text-nell-white'>
                {label}
              </Listbox.Label>
            )}
            <div className='relative'>
              <Listbox.Button
                disabled={disabled}
                className={
                  className
                    ? className
                    : `${
                        open ? 'border-nell-blue' : 'border-nell-light-grey'
                      } border-2  rounded-[5px] relative w-full  pl-6 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm `
                }
              >
                <span
                  className='block align-middle truncate text-nell-white text-base'
                  style={textStyle}
                >
                  {preValue ? `${preValue}: ` : ''}
                  {selectedValue?.label ? (
                    selectedValue?.label
                  ) : (
                    <span className='text-nell-light-grey text-base truncate'>
                      {placeholder ? placeholder : 'Select'}
                    </span>
                  )}
                </span>
                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                  {open ? (
                    <ChevronUpIcon
                      style={textStyle}
                      className='h-5 w-5 text-nell-white'
                      aria-hidden='true'
                    />
                  ) : (
                    <ChevronDownIcon
                      style={textStyle}
                      className='h-5 w-5 text-nell-white'
                      aria-hidden='true'
                    />
                  )}
                </span>
              </Listbox.Button>

              <Transition show={open} as={Fragment}>
                <Listbox.Options
                  id={id}
                  className='absolute z-50 w-full bg-nell-black border-2 border-nell-blue shadow-lg max-h-44 rounded-[5px] mt-2 py-2 text-base overflow-auto focus:outline-none sm:text-sm'
                >
                  {options?.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-primary-leaf bg-primary-leaf/20' : 'text-primary-leaf',
                          'cursor-default select-none relative py-2 pl-6 pr-9'
                        )
                      }
                      value={option?.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold text-primary-leaf' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-primary-leaf' : 'text-primary-leaf',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  }
);
