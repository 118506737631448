import { CountDown, Modal, Spiner } from 'components/shared';
import React from 'react';
import { checkIsEmail, checkIsNumbers, translate } from 'utils/HelperFunctions';

type Props = {
  loading: boolean;
  isForgetVisible: boolean;
  setIsForgetVisible: (visible: boolean) => void;
  isResetVisible: boolean;
  setIsResetVisible: (visible: boolean) => void;
  setEmail: (e: any) => void;
  email: string;
  resetPasswordState: { [key: string]: any };
  setResetPasswordState: (e: any) => void;
  onChangeEmail: () => void;
  onResetPassword: () => void;
  onResend: () => void;
};

export const ForgetPassword = (props: Props) => {
  const {
    loading,
    isForgetVisible,
    setIsForgetVisible,
    isResetVisible,
    setIsResetVisible,
    setEmail,
    email,
    onChangeEmail,
    resetPasswordState,
    setResetPasswordState,
    onResetPassword,
    onResend,
  } = props;

  return (
    <Modal
      visible={isForgetVisible}
      onClose={() => {
        setIsForgetVisible(false);
        setIsResetVisible(false);
        setEmail('');
        setResetPasswordState({ token: '', password: '', confirmation: '' });
      }}
      modalTitle={translate('loginScreen.resetPassword')}
    >
      <div className='flex flex-col gap-8 px-4 md:px-8'>
        {!isResetVisible ? (
          <>
            <p className='text-left'>{translate('loginScreen.enterYourEmailToSendRequest')}</p>
            <div>
              <label htmlFor='newEmail' className='block text-sm text-nell-blue mb-2'>
                {translate('common.email')}
              </label>
              <div className=''>
                <input
                  type={'email'}
                  id={'newEmail'}
                  autoComplete='off'
                  autoFocus
                  placeholder={translate('common.enterEmail')}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onKeyUp={event =>
                    event.key === 'Enter' && !loading && checkIsEmail(email) && onChangeEmail()
                  }
                  className='tw-input'
                />
              </div>
            </div>
            <div className='w-full'>
              <button
                type='button'
                disabled={loading || !checkIsEmail(email)}
                className='tw-button w-full'
                onClick={onChangeEmail}
              >
                {loading && <Spiner className='ml-40' />}
                {translate('loginScreen.submit')}
              </button>
            </div>
          </>
        ) : (
          <>
            {' '}
            <p className='text-left'>{translate('loginScreen.useTheCodeSentByEmail')}</p>
            <div>
              <label htmlFor='code' className='block text-sm text-nell-blue mb-2'>
                {translate('loginScreen.verificationCode')}
              </label>
              <div className='relative'>
                <input
                  type='text'
                  id='code'
                  autoComplete='off'
                  autoFocus
                  className='tw-input'
                  placeholder={translate('loginScreen.enterCode')}
                  value={resetPasswordState.token}
                  onChange={e =>
                    checkIsNumbers(e.target.value) &&
                    setResetPasswordState({ ...resetPasswordState, token: e.target.value })
                  }
                  onKeyUp={event =>
                    event.key === 'Enter' &&
                    !loading &&
                    resetPasswordState.token &&
                    resetPasswordState.password &&
                    resetPasswordState.confirmation &&
                    onResetPassword()
                  }
                />
                <CountDown duration={30} onResend={onResend} />
              </div>
            </div>
            <div>
              <label htmlFor='newPassword' className='block text-sm text-nell-blue mb-2'>
                {translate('common.newPassword')}
              </label>
              <input
                type='password'
                id='newPassword'
                autoComplete='off'
                className='tw-input'
                placeholder={translate('common.enterNewPassword')}
                value={resetPasswordState.password}
                onChange={e =>
                  setResetPasswordState({ ...resetPasswordState, password: e.target.value })
                }
                onKeyUp={event =>
                  event.key === 'Enter' &&
                  !loading &&
                  resetPasswordState.token &&
                  resetPasswordState.password &&
                  resetPasswordState.confirmation &&
                  onResetPassword()
                }
              />
            </div>
            <div>
              <label htmlFor='confirmed' className='block text-sm text-nell-blue mb-2'>
                {translate('common.confirmPassword')}
              </label>
              <input
                type='password'
                id='confirmed'
                autoComplete='off'
                className='tw-input'
                placeholder={translate('common.enterPasswordAgain')}
                value={resetPasswordState.confirmation}
                onChange={e =>
                  setResetPasswordState({ ...resetPasswordState, confirmation: e.target.value })
                }
                onKeyUp={event =>
                  event.key === 'Enter' &&
                  !loading &&
                  resetPasswordState.token &&
                  resetPasswordState.password &&
                  resetPasswordState.confirmation &&
                  onResetPassword()
                }
              />
            </div>
            <div className='w-full'>
              <button
                type='button'
                disabled={
                  loading ||
                  !resetPasswordState.token ||
                  !resetPasswordState.password ||
                  !resetPasswordState.confirmation
                }
                className='tw-button w-full'
                onClick={onResetPassword}
              >
                {loading && <Spiner className='ml-56 md:ml-60' />}
                {translate('loginScreen.resetPassword')}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
