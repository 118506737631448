const APIs: any = {
  abilities: {
    oauth: {
      post: {
        '/token': {
          I: 'token',
          A: 'post',
          M: 'Create a token through login',
        },
      },
      delete: {
        '/accessToken/:token': {
          I: 'accessToken',
          A: 'delete',
          M: 'delete accessToken ',
        },
        '/refreshToken/:token': {
          I: 'refreshToken',
          A: 'delete',
          M: 'delete refreshToken',
        },
      },
    },
    urac: {
      get: {
        '/password/forgot/code': {
          I: 'code',
          A: 'get',
          M: 'Get code to verify forgot password',
        },
        '/user/me': {
          I: 'me',
          A: 'get',
          M: 'get user data',
        },
        '/validate/changeEmail': {
          I: 'changeEmail',
          A: 'get',
          M: 'validate change email',
        },
        '/admin/groups': {
          I: 'adminGroups',
          A: 'get',
          M: 'get admin groups',
        },
        '/admin/group': {
          I: 'adminGroup',
          A: 'get',
          M: 'get admin group By id',
        },
      },
      post: {
        '/admin/group': {
          I: 'adminGroup',
          A: 'post',
          M: 'post admin group',
        },
      },
      put: {
        '/account': {
          I: 'account',
          A: 'put',
          M: 'Edit account information by id',
        },
        '/account/email/code': {
          I: 'email',
          A: 'put',
          M: 'Change account email by id - a code will be emailed',
        },
        '/account/password': {
          I: 'passwordAccount',
          A: 'put',
          M: 'Change password  by code',
        },
        '/password/reset': {
          I: 'password',
          A: 'put',
          M: 'Change password  by code',
        },
        '/admin/group': {
          I: 'adminGroup',
          A: 'put',
          M: 'put admin group By id',
        },
      },
      delete: {
        '/admin/group': {
          I: 'adminGroup',
          A: 'delete',
          M: 'delete admin group By id',
        },
      },
    },
    organization: {
      get: {
        '/companies': {
          I: 'companies',
          A: 'get',
          M: 'get all companies',
        },
        '/locations': {
          I: 'locations',
          A: 'get',
          M: 'Get all locations',
        },
        '/location/:locationId/active': {
          I: 'locationById',
          A: 'get',
          M: 'Get active location details by providing locationId',
        },
        '/company/:companyId/active': {
          I: 'companyById',
          A: 'get',
          M: 'Get active company details by providing companyId',
        },
        '/company/users': {
          I: 'CompanieUsers',
          A: 'get',
          M: 'get all company users',
        },
        '/company/groups': {
          I: 'companyGroups',
          A: 'get',
          M: 'Get all company groups',
        },
        '/location/users': {
          I: 'locationUsers',
          A: 'get',
          M: 'get all location users',
        },
        '/location/groups': {
          I: 'locationGroups',
          A: 'get',
          M: 'Get all location groups',
        },
        ' /company/locations': {
          I: 'companyLocations',
          A: 'get',
          M: 'Get all locations under company',
        },
        '/product': {
          I: 'product',
          A: 'get',
          M: 'Get product by code',
        },
        '/company/location/:locationId': {
          I: 'companyLocationById',
          A: 'get',
          M: 'Get location under company By id',
        },
        '/company/location/:locationId/users': {
          I: 'Locationusers',
          A: 'get',
          M: 'Get user under location By id',
        },
        '/company/location/:locationId/groups': {
          I: 'Locationgroups',
          A: 'get',
          M: 'Get groups under location By id',
        },
      },
      post: {
        '/company/location/:type': {
          I: 'location',
          A: 'post',
          M: 'post location under company',
        },
      },
      put: {
        '/company': {
          I: 'company',
          A: 'put',
          M: 'put  company',
        },
        '/location': {
          I: 'location',
          A: 'put',
          M: 'put  location',
        },
        '/company/user/:userId/groups': {
          I: 'companyGroups',
          A: 'put',
          M: 'put company groups ',
        },
        '/location/user/:userId/groups': {
          I: 'locationGroups',
          A: 'put',
          M: 'put location groups ',
        },
        '/company/location/:locationId/status': {
          I: 'locationStatus',
          A: 'put',
          M: 'put location status ',
        },
        '/company/location/:locationId': {
          I: 'compLocation',
          A: 'put',
          M: 'put location under company by id ',
        },
        '/company/location/:locationId/user/:userId/groups': {
          I: 'userGroups',
          A: 'put',
          M: 'put groups under location user ',
        },
      },
      delete: {
        '/company/user/:userId': {
          I: 'companyUser',
          A: 'delete',
          M: 'delete user under company',
        },
        '/location/user/:userId': {
          I: 'locationUser',
          A: 'delete',
          M: 'delete user under location',
        },
        '/company/location/:locationId/user/:userId': {
          I: 'locationUser',
          A: 'delete',
          M: 'delete user under location',
        },
      },
    },
    onboarding: {
      post: {
        '/company/user': {
          I: 'userCompany',
          A: 'post',
          M: 'Create user under a company',
        },
        '/location/user': {
          I: 'userLocation',
          A: 'post',
          M: 'Create user under a location',
        },
        '/company/location/:locationId/user': {
          I: 'userLocComp',
          A: 'post',
          M: 'Create user under a Company location',
        },
      },
    },
  },
  versions: {
    urac: '3',
    oauth: '1',
    organization: '1',
    onboarding: '2',
  },
};

export default APIs;
