import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Menu, Transition, RadioGroup } from '@headlessui/react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ChevronDownIcon, XIcon } from '@heroicons/react/outline';
import { useStore } from 'services/context';
import { saveExtKey, switchLanguage } from 'services/context/auth/Actions';
import { translate } from 'utils/HelperFunctions';
import { ReactComponent as PersonIcon } from 'assets/images/Person.svg';
import { useTranslation } from 'react-i18next';
import { Modal, Spiner } from 'components/shared';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { OrganizationServices } from 'services/apis/Organization';
import { updateCompanies, updateCompany } from 'services/context/Organization/actions';
import { toast } from 'react-toastify';
import { AuthSessionServices } from 'services/apis/AuthSession';

export const TopbarMenu = () => {
  const [state, dispatch] = useStore();
  const { userData } = state.authStore;
  const { organizationStore } = state;
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [allCompanies, setAllCompanies] = useState<any>([]);
  const [companyTypes, setCompanyTypes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAccountsVisible, setIsAccountsVisible] = useState<boolean>(false);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const companies = await OrganizationServices.fetchCompanies();
      const locations = await OrganizationServices.fetchLocations();
      const companyItems = companies?.items;
      const locationItems = locations?.items;
      setSelectedAccountId(
        [...companyItems, ...locationItems].find(
          account => account._id === organizationStore?.selectedCompany?._id
        )?._id || ''
      );
      dispatch(updateCompanies([...companyItems, ...locationItems]));

      setAllCompanies([...companyItems, ...locationItems]);
      const newType: any[] = [];
      if ([...companyItems, ...locationItems].length > 0) {
        [...companyItems, ...locationItems].forEach(com => {
          if (!newType.includes(com.type)) {
            newType.push(com.type);
          }
        });
      }
      setCompanyTypes(newType);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setAllCompanies([]);
    setCompanyTypes([]);
    fetchAccounts();
  }, []);

  useEffect(() => {
    const matchedCompany = organizationStore.companies.find(
      (company: any) => company._id === organizationStore?.selectedCompany?._id
    );
    if (matchedCompany) {
      setSelectedAccountId(matchedCompany._id);
    }
  }, [organizationStore.companies, organizationStore?.selectedCompany]);

  const onSwitchAccount = async () => {
    const accountType = allCompanies.find((company: any) => company._id === selectedAccountId);
    setIsLoading(true);
    try {
      if (accountType.type.includes('location')) {
        const location = await OrganizationServices.fetchLocationById(selectedAccountId);
        dispatch(
          updateCompany(
            allCompanies.find((currentLocation: any) => currentLocation._id === location._id) || {}
          )
        );
        dispatch(saveExtKey(location?.tenant?.currentProduct?.keyInfo?.extKey));
      } else {
        const company = await OrganizationServices.fetchCompanyById(selectedAccountId);
        dispatch(
          updateCompany(
            allCompanies.find((currentCompany: any) => currentCompany._id === company._id) || {}
          )
        );
        dispatch(saveExtKey(company?.tenant?.currentProduct?.keyInfo?.extKey));
      }
      navigate('/dashboard');
      window.location.reload();
      setIsLoading(false);
      setIsAccountsVisible(false);
      // toast.success(translate('settingsScreen.accountSwitchedSuccessfully'));
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.message);
    }
  };

  const onLogout = async () => {
    await AuthSessionServices.clearSession();
  };

  const getSwitchedToLang = () => (i18n.language.startsWith('en') ? 'Español' : 'English');

  const onChangeLanguage = () => {
    const lang = i18n.language.startsWith('en') ? 'es' : 'en';
    i18n.changeLanguage(lang);
    dispatch(switchLanguage(lang));
    setVisible(false);
  };

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  useOutsideAlerter(wrapperRef, handleOutsideClick);

  const _renderConfirmChangeLanguage = () => (
    <Modal visible={visible} onClose={() => setVisible(false)}>
      <p className='text-white text-3xl font-black text-center'>
        {translate('settingsScreen.confirmChangeLanguage', { language: getSwitchedToLang() })}
      </p>
      <div className='flex justify-between items-center py-8 space-x-3 sm:space-x-0'>
        <div>
          <button
            className='rounded-btn-outline !border-white !text-white !h-13 !px-16'
            onClick={() => setVisible(false)}
          >
            {translate('common.cancel')}
          </button>
        </div>
        <div>
          <button className='tw-button !text-sm !h-13' onClick={() => onChangeLanguage()}>
            {translate('common.confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );

  const _switchAccountModal = () => {
    return (
      <Modal
        visible={isAccountsVisible}
        onClose={() => setIsAccountsVisible(false)}
        hideXIcon={false}
        modalTitle={translate('common.accounts')}
        width={'500px'}
      >
        <div className='flex flex-col space-y-4 mb-6 px-8'>
          <RadioGroup value={selectedAccountId} onChange={setSelectedAccountId}>
            <RadioGroup.Label className='sr-only'>Accounts</RadioGroup.Label>
            <div
              className={
                'flex relative  flex-col justify-center items-start flex-wrap gap-3 w-full'
              }
            >
              {!loading ? (
                companyTypes.map((type: string) => (
                  <div key={type} className={`w-full flex flex-col gap-3`}>
                    <div className='font-heavyGaret text-2xl capitalize justify-start'>
                      {type === 'company'
                        ? translate(`common.companies`)
                        : type === 'location'
                        ? translate(`common.locations`)
                        : ''}
                    </div>
                    {allCompanies.map((account: { [key: string]: any }, index: number) =>
                      account.type === type ? (
                        <div key={index}>
                          <div className='flex flex-col gap-3'>
                            <RadioGroup.Option
                              key={index}
                              value={account._id}
                              className={() =>
                                'flex items-center relative bg-nell-dark-grey border-white border rounded-[10px] shadow-sm px-6 h-12 cursor-pointer sm:flex sm:justify-center focus:outline-none'
                              }
                            >
                              {({ checked }) => (
                                <div className='flex items-center'>
                                  <div>
                                    <div>
                                      <RadioGroup.Label
                                        as='p'
                                        className='font-medium text-lg text-center '
                                      >
                                        {account.name}
                                      </RadioGroup.Label>
                                    </div>
                                  </div>

                                  <RadioGroup.Description
                                    as='div'
                                    className='absolute text-sm sm:mt-0 right-4 sm:ml-4 sm:text-center'
                                  >
                                    <div
                                      className={`${
                                        checked ? 'relative' : ''
                                      } bg-nell-white  rounded-full h-5 w-5`}
                                    >
                                      {checked ? (
                                        <div className='h-3 w-3 bg-nell-blue rounded-full absolute inset-1 ' />
                                      ) : null}
                                    </div>
                                  </RadioGroup.Description>
                                </div>
                              )}
                            </RadioGroup.Option>
                          </div>
                        </div>
                      ) : (
                        ''
                      )
                    )}
                  </div>
                ))
              ) : (
                <div className='h-[100px] w-full flex justify-center items-center'>
                  <Spiner />
                </div>
              )}
            </div>
          </RadioGroup>
          <div className='text-center self-center'>
            <button
              type='button'
              disabled={isLoading || !selectedAccountId}
              className='tw-button mt-5'
              onClick={onSwitchAccount}
            >
              {isLoading && <Spiner className='ml-36' />}
              {translate('common.accountSwitch')}
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <>
      {_renderConfirmChangeLanguage()}
      {_switchAccountModal()}

      <Menu as='div' className='relative'>
        <>
          <div ref={wrapperRef}>
            <Menu.Button
              className={`md:w-52 flex items-center relative focus:outline-none outline-none space-x-2 p-3 before:lg:p-0 lg:before:shadow-none  ${
                isOpen
                  ? 'border-2 border-nell-blue border-b-0 rounded-[10px] rounded-b-none bg-nell-black invisible md:visible'
                  : 'hover:bg-nell-light-grey hover:rounded-[10px]'
              }`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className='h-9 w-9 shrink-0 z-10 leading-10 flex items-center justify-center rounded-full font-black text-xl text-primary-dark  bg-nell-blue'>
                <PersonIcon />
              </div>
              <p
                className='text-[18px] truncate font-normal leading-6 text-white hidden md:block'
                title={`${userData?.firstName} ${userData?.lastName}`}
              >
                {userData?.firstName} {userData?.lastName}
              </p>
              <ChevronDownIcon
                className={`${
                  isOpen ? '-rotate-180' : ''
                } text-white h-5 w-5 hidden md:block transition-all`}
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
            show={isOpen}
          >
            <Menu.Items
              className={`origin-center absolute z-50 right-0 top-4 md:top-auto w-52 rounded-t-[10px] md:rounded-t-none rounded-b-[10px] md:border-t-0 ${
                isOpen ? ' border-nell-blue border-2' : ''
              } py-1 bg-nell-black text-nell-white ring-0 ring-opacity-5 focus:outline-none`}
            >
              <div className='md:hidden'>
                <Menu.Button
                  className={`w-full flex items-center relative focus:outline-none outline-none space-x-2 p-3 before:lg:p-0 lg:before:shadow-none`}
                >
                  <div className='h-9 w-9 shrink-0 z-10 leading-10 flex items-center justify-center rounded-full font-black text-xl text-primary-dark'>
                    <PersonIcon />
                  </div>
                  <p
                    className='text-[18px] truncate font-normal leading-6 text-white'
                    title={`${userData?.firstName} ${userData?.lastName}`}
                  >
                    {userData?.firstName} {userData?.lastName}
                  </p>
                  <XIcon
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className={`text-white h-7 w-7 transition-all`}
                  />
                </Menu.Button>
              </div>
              <Menu.Item>
                <NavLink
                  to={'/dashboard/profile'}
                  className='hover:font-bold block px-4 py-2 text-lg capitalize cursor-pointer'
                >
                  {translate(`common.profile`)}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  to={'#'}
                  className='hover:font-bold block px-4 py-2 text-lg capitalize cursor-pointer'
                  onClick={() => {
                    fetchAccounts();
                    setIsAccountsVisible(true);
                  }}
                >
                  {translate(`common.accounts`)}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to={'#'}
                  className='hover:font-bold block px-4 py-2 text-lg capitalize cursor-pointer'
                  onClick={onLogout}
                >
                  {translate(`common.logout`)}
                </Link>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      </Menu>
    </>
  );
};
