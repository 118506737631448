import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginParams } from 'services/apis/Auth/auth.types';
import { AuthServices } from 'services/apis/Auth/AuthServices';
import { OrganizationServices } from 'services/apis/Organization/OrganizationServices';
import { saveExtKey } from 'services/context/auth/Actions';
import { translate, checkIsEmail } from 'utils/HelperFunctions';
import { toast } from 'react-toastify';
import { Spiner } from 'components/shared/Spiner';
import Logo from 'assets/images/NellEnterpriseLogo.svg';
import { useStore } from 'services/context';
import { updateCompanies, updateCompany } from 'services/context/Organization/actions';
import NLogo from 'assets/images/NLogo.png';
import { ForgetPassword } from './ForgetPassword';
import { AuthSessionServices } from 'services/apis/AuthSession';

export const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<LoginParams>({
    username: '',
    password: '',
  });
  const [isForgetVisible, setIsForgetVisible] = useState<boolean>(false);
  const [isResetVisible, setIsResetVisible] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [resetPasswordState, setResetPasswordState] = useState({
    token: '',
    password: '',
    confirmation: '',
  });
  const [_, dispatch] = useStore();

  useEffect(() => {
    return () => {
      setLoginData({
        username: '',
        password: '',
      });
    };
  }, []);

  const onLogin = async () => {
    try {
      if (loginData.username && loginData.password) {
        setLoading(true);
        loginData.username = loginData.username.toLowerCase();
        await AuthServices.login(loginData);
        await AuthSessionServices.createSession();
        const companies = await OrganizationServices.fetchCompanies();
        const locations = await OrganizationServices.fetchLocations();
        const companyItems = companies?.items;
        const locationItems = locations?.items;
        dispatch(updateCompanies([...companyItems, ...locationItems]));

        if (companyItems?.length > 0) {
          dispatch(updateCompany(companyItems[0]));
          const company = await OrganizationServices.fetchCompanyById(companyItems[0]._id);
          dispatch(saveExtKey(company?.tenant?.currentProduct?.keyInfo?.extKey));
          setLoading(false);
          return navigate('/dashboard', { replace: true });
        } else if (locationItems?.length > 0) {
          dispatch(updateCompany(locationItems[0]));
          const location = await OrganizationServices.fetchLocationById(locationItems[0]._id);
          dispatch(saveExtKey(location?.tenant?.currentProduct?.keyInfo?.extKey));
          setLoading(false);
          return navigate('/dashboard', { replace: true });
        } else {
          setLoading(false);
          return navigate('/safetypage', { replace: true });
        }
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const onChangeEmail = async () => {
    try {
      setLoading(true);
      await AuthServices.forgotPasswordCode(email)
        .then((forgotCode: any) => {
          setLoading(false);

          if (forgotCode) {
            setIsResetVisible(true);
            toast.success(translate('loginScreen.yourRequestWasSentSuccessfully'));
          }
          return;
        })
        .catch((error: any) => {
          toast.error(error.message);
          setLoading(false);
        });
    } catch (error) {}
  };

  const onResetPassword = () => {
    if (resetPasswordState.password !== resetPasswordState.confirmation) {
      toast.error(translate('loginScreen.thePasswordAndItsConfirmationDoNotMatch'));
      return;
    }
    try {
      setLoading(true);
      AuthServices.resetPassword(resetPasswordState)
        .then(() => {
          toast.success(translate('resetPasswordScreen.resetPasswordSuccess'));
          setIsForgetVisible(false);
          setIsResetVisible(false);
          setLoading(false);
          setEmail('');
          setResetPasswordState({
            token: '',
            password: '',
            confirmation: '',
          });
          return;
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(error.message);
        });
    } catch (error) {}
  };

  const onResend = async () => {
    if (email) {
      setResetPasswordState({ ...resetPasswordState, token: '' });
      try {
        await AuthServices.forgotPasswordCode(email)
          .then((forgotCode: any) => {
            if (forgotCode) {
              setIsResetVisible(true);
              toast.success(translate('resetPasswordScreen.resendCodeSuccess'));
            }
            return;
          })
          .catch((error: any) => {
            toast.error(error.message);
            setLoading(false);
          });
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {isForgetVisible && (
        <ForgetPassword
          loading={loading}
          isForgetVisible={isForgetVisible}
          setIsForgetVisible={setIsForgetVisible}
          isResetVisible={isResetVisible}
          setIsResetVisible={setIsResetVisible}
          setEmail={setEmail}
          email={email}
          onChangeEmail={onChangeEmail}
          resetPasswordState={resetPasswordState}
          setResetPasswordState={setResetPasswordState}
          onResetPassword={onResetPassword}
          onResend={onResend}
        />
      )}
      <div className='min-h-screen flex items-center justify-center lg:bg-NLogoBackground bg-center text-nell-white py-10 px-0 sm:px-10 bg-no-repeat'>
        <div className='sm:border-2 sm:border-nell-blue sm:rounded-[20px] flex flex-col justify-center items-center lg:flex-row w-[500px] lg:w-[845px] lg:h-[596px] overflow-hidden xz-20'>
          <div className='bg-none sm:bg-gradient-to-b lg:bg-gradient-to-t from-nell-secondary-blue to-nell-blue w-full lg:w-[345px] h-[180px] lg:h-full flex flex-row lg:flex-col grow items-center justify-center gap-10'>
            <img src={NLogo} alt='' className='hidden sm:block w-[120px]' />
            <img src={Logo} alt='' className='' />
          </div>

          <div className='bg-nell-black grow sm:w-[500px] h-full p-4 sm:p-10 flex flex-col items-center justify-center gap-8'>
            <h2 className='font-prototype font-normal tracking-wide text-5xl'>
              {translate('loginScreen.welcomeBack')}
            </h2>
            <p className='text-2xl font-normal mt-5'>
              {translate('loginScreen.pleaseSignInToContinue')}
            </p>
            <div className='w-full'>
              <label htmlFor='name' className='block text-sm text-nell-blue mb-2'>
                {translate('common.email')}
              </label>
              <input
                type={'text'}
                autoComplete='on'
                autoFocus
                className={`tw-input`}
                placeholder={translate('common.enterEmail')}
                value={loginData.username}
                onKeyUp={event => event.key === 'Enter' && onLogin()}
                onChange={e => setLoginData({ ...loginData, username: e.target.value })}
              />
            </div>
            <div className='w-full'>
              <label htmlFor='name' className='block text-sm text-nell-blue mb-2'>
                {translate('common.password')}
              </label>
              <input
                type={'password'}
                autoComplete='off'
                className={`tw-input`}
                placeholder={translate('common.enterPassword')}
                onKeyUp={event => event.key === 'Enter' && onLogin()}
                value={loginData.password}
                onChange={e => setLoginData({ ...loginData, password: e.target.value })}
              />
            </div>
            <div className=''>
              <button
                className='underline'
                onClick={() => {
                  setIsForgetVisible(true);
                }}
              >
                {translate('loginScreen.forgotYourPassword')}
              </button>
            </div>
            <div className='w-full'>
              <button
                type='button'
                onClick={onLogin}
                disabled={
                  loading ||
                  !loginData.username ||
                  !loginData.password ||
                  !checkIsEmail(loginData.username)
                }
                className='tw-button w-full'
              >
                {loading && <Spiner className='ml-40' />}
                {translate('loginScreen.login')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
