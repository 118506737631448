import { ICompany } from 'services/apis/Organization/Organization.types';
// import { isCompanyProfileCompleted } from 'utils/HelperFunctions';
import { StorageHelper } from 'utils/StorageServices';
import { actions } from './actions';

export const initStateOrganization: {
  companies: any[];
  isThereCompany: boolean;
  isTherePendingConnect: boolean;
  selectedCompany?: ICompany;
  isCompanyProfileCompleted: boolean;
} = {
  companies: [],
  selectedCompany: StorageHelper.getSelectedCompany(),
  isTherePendingConnect: StorageHelper.getPendingConnect(),
  isThereCompany: JSON.parse(localStorage.getItem('isThereCompany') || 'false'),
  isCompanyProfileCompleted: StorageHelper.getIsCompanyProfileCompleted(),
};

export type OrganizationState = typeof initStateOrganization;

const OrganizationReducer = (action: any, state: OrganizationState = initStateOrganization) => {
  switch (action?.type) {
    case actions.UPDATE_COMPANIES:
      const companies = action.payload;
      localStorage.setItem('isThereCompany', companies.length > 0 ? 'true' : 'false');
      return {
        ...state,
        isThereCompany: companies.length > 0 ? true : false,
        companies: companies,
      };
    case actions.UPDATE_COMPANY:
      const updateSelectedCompany = { ...(state.selectedCompany || {}), ...(action.payload || {}) };
      StorageHelper.setSelectedCompany(updateSelectedCompany);
      // const isCompanyCompleted = isCompanyProfileCompleted(action.payload);
      // StorageHelper.setIsCompanyProfileCompleted(isCompanyCompleted);
      return {
        ...state,
        selectedCompany: updateSelectedCompany,
        // isCompanyProfileCompleted: isCompanyCompleted,
      };
    case actions.UPDATE_PENDING_CONNECT_COMPANIES:
      const flagPending: boolean = action.payload.some(
        (company: any) => company?.connect?.type === 'inviteRequest'
      );
      StorageHelper.setPendingConnect(flagPending);
      return {
        ...state,
        isTherePendingConnect: flagPending,
      };
    default:
      return state;
  }
};

export default OrganizationReducer;
