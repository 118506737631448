import React from 'react';
import { classNames } from 'utils/HelperFunctions';

type TabsProps = {
  activeKey: string;
  className?: string;
  onChange: (activeKey: string) => void;
  tabPanes: {
    name: string;
    key: string;
    hasBadge?: boolean;
    disabled?: boolean;
  }[];
};
export const Tabs: React.FC<TabsProps> = React.memo(
  ({ activeKey, tabPanes, onChange, className = '' }) => {
    return (
      <div className={className}>
        <div className='block'>
          <div className='overflow-x-auto no-scrollbar'>
            <div className='sm:border-b-2 border-nell-light-grey'>
              <nav className='flex justify-center sm:justify-start items-center' aria-label='Tabs'>
                {tabPanes.map(tabPane => (
                  <div
                    key={tabPane.key}
                    onClick={() => onChange(tabPane.key)}
                    className={classNames(
                      tabPane.key === activeKey
                        ? 'text-white bg-primary-blue sm:bg-transparent font-heavyGaret font-black border-b-2 sm:border-b-nell-blue '
                        : 'text-primary-light-grey  font-garet font-normal hover:font-bold hover:text-gray-500 border-b-2 border-transparent text-nell-grey-primary md:text-nell-light-grey ',
                      `bg-nell-blue sm:bg-transparent whitespace-nowrap py-2 px-3 sm:px-8 w-full sm:w-fit flex capitalize sm:uppercase justify-center items-center cursor-pointer text-2xl sm:text-[16px] text-center mb-[0px] xz-10 h-12  ${
                        tabPane.disabled ? 'cursor-not-allowed' : ''
                      }`
                    )}
                    aria-current={tabPane.key === activeKey ? 'page' : undefined}
                  >
                    {tabPane.name}
                    {tabPane.hasBadge && (
                      <span className='bg-secondary-color w-[9px] h-[9px] -translate-y-3 rounded-full inline-block'>
                        {}
                      </span>
                    )}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
