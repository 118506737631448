import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'utils/axiosInterceptor';
import { ToastContainer } from 'react-toastify';
import { StoreProvider } from 'services/context/Provider';
import { Outlet } from 'react-router-dom';
import AuthLoading from 'AuthLoading';

function App() {
  return (
    <StoreProvider>
      <AuthLoading />
      <ToastContainer />
      <Outlet />
    </StoreProvider>
  );
}

export default App;
