import axios from 'axios';
import { getExtKey } from 'utils/HelperFunctions';
import { InviteMembership } from './Onboarding.types';

export const OnboardingServices = {
  //Used API
  inviteUserByCompany: async (payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `onboarding/company/user`;
      const invitedUser = await axios.post(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return invitedUser;
    } catch (error) {
      throw error;
    }
  },
  inviteUserByLocation: async (payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `onboarding/location/user`;
      const invitedUser = await axios.post(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return invitedUser;
    } catch (error) {
      throw error;
    }
  },
  onAddNewLocationUser: async (locationId: string, payload: any) => {
    const extKey = getExtKey();
    const endPoint = `onboarding/company/location/${locationId}/user`;
    try {
      const addedUser = await axios.post(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return addedUser;
    } catch (error) {
      throw error;
    }
  },
  //---------------

  fetchAminsUserList: async (): Promise<any> => {
    const endPoint = `onboarding/users/admin`;
    try {
      const adminsUserList = await axios.get(endPoint);
      return adminsUserList;
    } catch (error) {
      throw error;
    }
  },
  inviteMembership: async (body: InviteMembership): Promise<any> => {
    const endPoint = `onboarding/invite`;
    try {
      const invitedMembership = await axios.post(endPoint, body);
      return invitedMembership;
    } catch (error) {
      throw error;
    }
  },
  addUserAdmin: async (payload: any): Promise<any> => {
    const endPoint = `onboarding/user/admin`;
    try {
      const newUser = await axios.post(endPoint, payload);
      return newUser;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async (id: string): Promise<any> => {
    const endPoint = `onboarding/user/${id}/admin`;
    try {
      const validatedUser = await axios.delete(endPoint);
      return validatedUser;
    } catch (error) {
      throw error;
    }
  },
  unInviteUserByCompany: async (companyId: string, userId: string): Promise<any> => {
    const endPoint = `organization/company/${companyId}/user/${userId}/uninvite`;
    try {
      const unInvitedUser = await axios.put(endPoint);
      return unInvitedUser;
    } catch (error) {
      throw error;
    }
  },
};
