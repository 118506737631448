import { useEffect, useState } from 'react';
import {
  scrollTop,
  getStatusCardClass,
  getStatusClass,
  translate,
  calcPagination,
} from 'utils/HelperFunctions';
import { Pagination, Select, Table } from 'components/shared';
import { BsPlus } from 'react-icons/bs';
import { SearchIcon } from '@heroicons/react/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import { Address, OrganizationServices } from 'services/apis/Organization';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { LocationsFilter } from './LocationsFilter';
import { UseAbility } from 'services/context';

let debounce: any = null;
export const Locations = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [locations, setLocations] = useState<any[]>([]);
  const [filter, setFilter] = useState<{ [key: string]: any }>({});
  const [totalLocations, setTotalLocations] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nameSort, setNameSort] = useState<'asc' | 'desc' | undefined>(undefined);
  const addressKeys = ['address1', 'address2', 'city', 'country', 'region', 'postalCode'];
  const canAddLocation = UseAbility('location', 'post');

  const fetchLocations = async (params = calcPagination()) => {
    try {
      const locationslist = await OrganizationServices.getCompanyLocationsbyType(params);
      setLocations(locationslist?.items);
      setTotalLocations(locationslist?.count);
      setCurrentPage(params.skip / params.limit + 1);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('companiesFilters') !== null) {
      const companiesFilters = JSON.parse(sessionStorage.getItem('companiesFilters') || '{}');
      if (companiesFilters['sortBy[0][field]'] === 'name') {
        setNameSort(companiesFilters['sortBy[0][type]']);
      }
      setFilter(companiesFilters);
      fetchLocations({ ...companiesFilters, limit: 5 });
    } else {
      fetchLocations();
    }
  }, []);

  const onChangeFilter = (newFilters = {}) => {
    sessionStorage.setItem('companiesFilters', JSON.stringify({ ...filter, ...newFilters } || {}));
    setFilter({ ...filter, ...newFilters });
    fetchLocations({ ...calcPagination(), ...filter, ...newFilters });
    scrollTop();
  };

  const _renderLocationsForMobile = () => {
    return locations?.map(location => {
      return (
        <div
          key={location?._id}
          className='bg-nell-mid-grey hover:bg-nell-light-grey text-nell-white rounded-[8px] p-4 flex items-start justify-between xmin-h-[105px] cursor-pointer mb-4'
          onClick={() => {
            navigate(`/dashboard/locations/${location._id}`);
          }}
        >
          <div className='overflow-hidden'>
            <h2 className='font-heavyGaret capitalize '>{location?.name || '-'}</h2>
            <p className=' ellipsis'>
              {addressKeys
                .filter(key => (location?.address?.[key as keyof Address] ? true : false))
                .map(key => location?.address?.[key as keyof Address])
                .join(', ') || ''}
            </p>
            <p className='truncate'>{location?.profile?.contactInfo?.email || '-'}</p>
          </div>
          <div
            className={`${getStatusCardClass(
              location?.status
            )} shrink-0 w-24 rounded-2xl text-center text-sm text-nell-black font-heavyGaret capitalize p-1`}
          >
            {location?.status || '-'}
          </div>
        </div>
      );
    });
  };

  const _renderLocations = () => {
    const columns = [
      {
        title: (
          <div className='flex flex-col items-start gap-2'>
            <div className='flex items-center'>
              <span>{translate('common.name')}</span>
              <span className='flex flex-col'>
                <MdKeyboardArrowUp
                  onClick={() => {
                    if (nameSort !== 'desc') {
                      delete filter['sortBy[0][field]'];
                      delete filter['sortBy[0][type]'];
                      setNameSort('desc');
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                        'sortBy[0][field]': 'name',
                        'sortBy[0][type]': 'desc',
                      });
                    } else {
                      setNameSort(undefined);
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                      });
                    }
                  }}
                  className={`text-2xl cursor-pointer ${
                    nameSort === 'desc' ? 'text-nell-blue' : ''
                  }`}
                />
                <MdKeyboardArrowUp
                  onClick={() => {
                    delete filter['sortBy[0][field]'];
                    delete filter['sortBy[0][type]'];
                    if (nameSort !== 'asc') {
                      setNameSort('asc');
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                        'sortBy[0][field]': 'name',
                        'sortBy[0][type]': 'asc',
                      });
                    } else {
                      setNameSort(undefined);
                      onChangeFilter({
                        ...filter,
                        skip: 0,
                      });
                    }
                  }}
                  className={`text-2xl rotate-180 -mt-3 cursor-pointer ${
                    nameSort === 'asc' ? 'text-nell-blue' : ''
                  } `}
                />
              </span>
            </div>
          </div>
        ),
        dataIndex: 'name',
        render: (name: string) => (
          <div className='break-words whitespace-normal flex items-center cursor-pointer'>
            {name}
          </div>
        ),
      },
      {
        title: translate('common.address'),
        dataIndex: 'address',
        render: (_: any, item: any) => (
          <div className='flex items-center cursor-pointer break-words whitespace-normal'>
            <div>
              {addressKeys
                .filter(key => (item?.address?.[key as keyof Address] ? true : false))
                .map(key => item?.address?.[key as keyof Address])
                .join(', ') || ''}
            </div>
          </div>
        ),
      },
      {
        title: (
          <div className='flex items-center gap-2 cursor-pointer' onClick={() => {}}>
            <span>{translate('common.email')}</span>
          </div>
        ),
        dataIndex: 'email',
        render: (_: any, item: any) => {
          return (
            <div className='flex items-center cursor-pointer break-all whitespace-normal'>
              {item?.profile?.contactInfo?.email}
            </div>
          );
        },
      },
      {
        title: (
          <div className='w-[120px]'>
            <Select
              options={[
                { value: 'all', label: 'All' },
                { value: 'new', label: 'New' },
                { value: 'pending', label: 'Pending' },
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' },
              ]}
              onChange={e => {
                if (e?.value === 'all') {
                  delete filter.status;
                  onChangeFilter({ ...filter, skip: 0 });
                } else {
                  onChangeFilter({ ...filter, skip: 0, status: e?.value });
                }
              }}
              value={filter.status}
              placeholder='Status'
              className='bg-nell-dark-grey'
            />
          </div>
        ),
        dataIndex: 'status',
        theadClass: 'w-0',
        align: 'center',
        render: (status: string) => {
          return <div className={`${getStatusClass(status)} capitalize`}>{status}</div>;
        },
      },
    ];

    const { limit, skip } = calcPagination(currentPage);

    return (
      <>
        <div className='pb-4 mt-7' style={{ boxShadow: '0px 2px 8px rgba(2, 1, 1, 0.153)' }}>
          <div className='hidden sm:block fixed bottom-10 sm:bottom-auto right-6 sm:absolute sm:top-6 text-center'>
            <button
              type='button'
              disabled={!canAddLocation}
              className='tw-button !border-0 sm:!border-2 !w-full h-14 !pr-4 !p-0'
              onClick={() => {
                navigate(`${pathname}/location/new`);
              }}
            >
              <BsPlus className='text-5xl' />
              <span className=''>{translate('locationsScreen.newLocation')}</span>
            </button>
          </div>
          <div className='sm:hidden fixed bottom-10 right-6'>
            <button
              type='button'
              className='tw-button !border-0 w-14 h-14 !p-0 !rounded-lg'
              onClick={() => {
                navigate(`${pathname}/location/new`);
              }}
            >
              <BsPlus className='text-5xl' />
            </button>
          </div>
          <hr className='hidden sm:block border-nell-light-grey mb-8' />
          <div className='relative px-4 mb-4'>
            <div className='absolute inset-y-0 left-4 pl-3 flex items-center pointer-events-none'>
              <SearchIcon className='h-5 w-5 text-nell-light-grey' aria-hidden='true' />
            </div>
            <input
              type='search'
              name='search'
              className='tw-input placeholder:text-sm w-full !rounded-[10px] !pl-10'
              placeholder={translate('locationsScreen.searchByLocationName')}
              value={filter?.keywords}
              onChange={e => {
                setFilter(() => ({ ...filter, keywords: e?.target?.value }));
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                  onChangeFilter({ ...filter, keywords: e?.target?.value });
                }, 300);
              }}
            />
          </div>
          <LocationsFilter
            onChange={onChangeFilter}
            filter={filter}
            setNameSort={setNameSort}
            clasName='sm:hidden'
          />
          <div className='hidden sm:block w-full'>
            <Table
              columns={columns}
              dataSource={locations}
              hover
              onClickRow={(_, location) => {
                navigate(`/dashboard/locations/${location._id}`);
              }}
            />
          </div>
          <div className='flex flex-col gap-4 sm:hidden px-4'>{_renderLocationsForMobile()}</div>
        </div>
        <div className='mb-24'>
          <Pagination
            count={totalLocations}
            limit={limit}
            skip={skip}
            calc={calcPagination}
            onChange={pageParams => {
              onChangeFilter(pageParams);
            }}
            className='sm:mt-5'
          />
        </div>
      </>
    );
  };

  return (
    <div className='relative sm:bg-nell-dark-grey sm:pt-20 mb-10 rounded-[20px] overflow-hidden'>
      {_renderLocations()}
    </div>
  );
};
