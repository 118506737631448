import axios from 'axios';
import { getExtKey } from 'utils/HelperFunctions';

type GeneralParams = {
  [key: string]: any;
};

export const OrganizationServices = {
  //used APIs
  fetchCompanies: async (params: GeneralParams = {}): Promise<any> => {
    const endPoint = `organization/companies`;
    try {
      const companies = await axios.get(endPoint, { params });
      return companies;
    } catch (error) {
      throw error;
    }
  },
  fetchLocations: async (params: GeneralParams = {}): Promise<any> => {
    const endPoint = `organization/locations`;
    try {
      const locations = await axios.get(endPoint, { params });
      return locations;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyById: async (companyId: string): Promise<any> => {
    try {
      const endPoint = `organization/company/${companyId}/active`;
      const response = await axios.get(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchLocationById: async (locationId: string): Promise<any> => {
    try {
      const endPoint = `organization/location/${locationId}/active`;
      const response = await axios.get(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyUsers: async (params: GeneralParams = {}): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/users`;
      const companyUsers = await axios.get(endPoint, {
        params,
        headers: {
          key: extKey,
        },
      });
      return companyUsers;
    } catch (error) {
      throw error;
    }
  },
  fetchAdminLocationUsers: async (params: GeneralParams = {}): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/location/users`;
      const companyUsers = await axios.get(endPoint, {
        params,
        headers: {
          key: extKey,
        },
      });
      return companyUsers;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyGroups: async (): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/groups`;
      const companyGroups = await axios.get(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return companyGroups;
    } catch (error) {
      throw error;
    }
  },
  fetchLocationGroups: async (): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/location/groups`;
      const companyGroups = await axios.get(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return companyGroups;
    } catch (error) {
      throw error;
    }
  },
  updateUserRoleByCompany: async (userId: string, payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/user/${userId}/groups`;
      const updatedUserRole = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return updatedUserRole;
    } catch (error) {
      throw error;
    }
  },
  updateUserRolebyLocation: async (userId: string, payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/location/user/${userId}/groups`;
      const updatedUserRole = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return updatedUserRole;
    } catch (error) {
      throw error;
    }
  },
  unInviteUserByCompany: async (userId: string): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/user/${userId}`;
      const unInvitedUser = await axios.delete(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return unInvitedUser;
    } catch (error) {
      throw error;
    }
  },
  unInviteUserByLocation: async (userId: string): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/location/user/${userId}`;
      const unInvitedUser = await axios.delete(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return unInvitedUser;
    } catch (error) {
      throw error;
    }
  },
  fetchGroupProducts: async (): Promise<any> => {
    const endPoint = `organization/product`;
    try {
      const extKey = getExtKey();
      const products = await axios.get(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return products;
    } catch (error) {
      throw error;
    }
  },
  getCompanyLocationsbyType: async (params: GeneralParams = {}) => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/locations`;
      const companylocations: any = await axios.get(endPoint, {
        params,
        headers: {
          key: extKey,
        },
      });
      return companylocations;
    } catch (error) {
      throw error;
    }
  },
  addCompanyLocation: async (type: string, payload: any) => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/location/${type}`;
      const newCompanyLocation = await axios.post(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return newCompanyLocation;
    } catch (error) {
      throw error;
    }
  },
  fetchLocationDetailsById: async (locationId: string): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/location/${locationId}`;
      const response = await axios.get(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  ChangeLocationStatus: async (locationId: string, payload: any) => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/location/${locationId}/status`;
      const locationStatus = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return locationStatus;
    } catch (error) {
      throw error;
    }
  },
  fetchLocationUsers: async (locationId: string, params: GeneralParams = {}) => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/location/${locationId}/users`;
      const adminLocationUsers: any = await axios.get(endPoint, {
        params,
        headers: {
          key: extKey,
        },
      });
      return adminLocationUsers;
    } catch (error) {
      throw error;
    }
  },
  fetchLocationRoles: async (locationId: string) => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/location/${locationId}/groups`;
      const adminLocationGroups: any = await axios.get(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return adminLocationGroups;
    } catch (error) {
      throw error;
    }
  },
  unInviteUserLocationUser: async (id: string, userId: string): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/location/${id}/user/${userId}`;
      const unInvitedUser = await axios.delete(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return unInvitedUser;
    } catch (error) {
      throw error;
    }
  },
  updateLocationUserRole: async (id: string, userId: string, payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/location/${id}/user/${userId}/groups`;
      const updatedUserRole = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return updatedUserRole;
    } catch (error) {
      throw error;
    }
  },
  onUpdateLocationDetails: async (locationId: string, payload: any) => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company/location/${locationId}`;
      const adminLocation = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return adminLocation;
    } catch (error) {
      throw error;
    }
  },
  updateCompanyProfile: async (payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/company`;
      const response = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateLocationProfile: async (payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/location`;
      const response = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  //------------

  getAdminLocation: async (userId: string) => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/admin/location/?id=${userId}`;
      const adminLocation = await axios.get(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return adminLocation;
    } catch (error) {
      throw error;
    }
  },
  onUpdateAdminLocationDetails: async (payload: any) => {
    try {
      const extKey = getExtKey();
      const endPoint = `organization/admin/location`;
      const adminLocation = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return adminLocation;
    } catch (error) {
      throw error;
    }
  },
};
