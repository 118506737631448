import React, { useReducer } from 'react';
import { StoreContext } from './context';
import AuthReducer, { initStateAuth } from './auth/Reducer';
import OrganizationReducer, { initStateOrganization } from './Organization/reducer';
import { combineReducers } from 'utils/HelperFunctions';
import AbilitiesReducer, { initStateAbilities } from './abilities/reducer';
import globalStore from './store';

const initialState = {
  authStore: initStateAuth,
  organizationStore: initStateOrganization,
  abilitiesStore: initStateAbilities,
};
const rootReducer = combineReducers({
  authStore: AuthReducer,
  organizationStore: OrganizationReducer,
  abilitiesStore: AbilitiesReducer,
});

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const store: any = React.useMemo(() => [state, dispatch], [state]);
  if (!globalStore.isReady) {
    globalStore.isReady = true;
    globalStore.dispatch = params => {
      return dispatch(params);
    };
    Object.freeze(globalStore);
  }
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
