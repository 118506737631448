import React, { useState } from 'react';
import Logo from 'assets/images/NellEnterpriseLogo.svg';
import { translate } from 'utils/HelperFunctions';
import { Spiner } from 'components/shared';
import { AuthSessionServices } from 'services/apis/AuthSession';

export const SafetyPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onLogout = async () => {
    setIsLoading(true);
    await AuthSessionServices.clearSession();
    setIsLoading(false);
  };

  return (
    <div className='bg-nell-black h-screen'>
      <div className='px-16 relative text-center flex flex-col md:flex-row lg:flex-row items-center z-50 justify-between'>
        <img src={Logo} alt='' className='mt-7' />
        <div className='mt-6 text-center  whitespace-nowrap'>
          <button type='button' className='tw-button' onClick={onLogout}>
            {isLoading && <Spiner />}
            {translate('common.logout')}
          </button>
        </div>
      </div>
      <div className='py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24'>
        <div className='absolute inset-0'>
          <div className='absolute inset-0 bg-primary-dark' aria-hidden='true'></div>
        </div>
        <div className='relative mx-auto pt-20 '>
          <div className='text-center'>
            <h2 className='text-3xl font-black tracking-tight text-nell-grey-primary sm:text-4xl'>
              {translate('common.safetyWelcome')}
            </h2>
            <p className='mt-3 text-base font-normal text-nell-grey-primary'>
              {translate('common.safetyWelcomeDes')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
