import { Tabs } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { translate } from 'utils/HelperFunctions';
import { Users } from './Users';
import { Roles } from './Roles';
import { useSearchParams } from 'react-router-dom';

export const UsersRolesSection = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'users',
  });
  const [activeKeyTab, setActiveKeyTab] = useState<string>('1');
  const tabParam = searchParams.get('tab');

  useEffect(() => {
    if (!tabParam || tabParam === 'users') {
      setActiveKeyTab('1');
    } else {
      setActiveKeyTab('2');
    }
  }, [tabParam]);

  const _renderTabs = () => {
    switch (activeKeyTab) {
      case '1':
        return <Users />;
      case '2':
        return <Roles />;
      default:
        return null;
    }
  };

  const _renderUserAndRoles = () => {
    return (
      <div className={`relative sm:bg-nell-dark-grey sm:pt-8 rounded-[20px] mb-4`}>
        <Tabs
          activeKey={activeKeyTab}
          onChange={key => {
            setActiveKeyTab(key);
            setSearchParams({ tab: key === '1' ? 'users' : 'groups' });
          }}
          tabPanes={[
            { name: translate('settingsScreen.users'), key: '1' },
            { name: translate('settingsScreen.roles'), key: '2' },
          ]}
        />
        {_renderTabs()}
      </div>
    );
  };

  return <>{_renderUserAndRoles()}</>;
};
