import { UserAccountProperties } from 'services/apis/Auth/auth.types';

export const actions: Record<string, string> = {
  SET_LOGIN_DATA: 'SET_LOGIN_DATA',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  UPDATE_USER_ACCOUNT: 'UPDATE_USER_ACCOUNT',
  SAVE_EXTKEY: 'SAVE_EXTKEY',
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
};

export const setLoginData = (payload: any) => ({
  type: actions.SET_LOGIN_DATA,
  payload,
});

export const setIsLoggedIn = (payload: boolean) => ({
  type: actions.SET_IS_LOGGED_IN,
  payload,
});

export const updateUserAccount = (payload: UserAccountProperties) => ({
  type: actions.UPDATE_USER_ACCOUNT,
  payload,
});

export const saveExtKey = (extKey: string) => ({
  type: actions.SAVE_EXTKEY,
  extKey,
});

export const switchLanguage = (language: 'en' | 'es') => ({
  type: actions.SWITCH_LANGUAGE,
  language: language,
});
