import { useContext } from 'react';
import { StoreContext } from './context';

export const useStore = () => {
  const [state, dispatch]: any = useContext(StoreContext);

  return [state, dispatch];
};

export const UseAbility = (I: any, A: any) => {
  const [state] = useStore();
  const { abilitiesStore } = state;
  if (Object.keys(abilitiesStore).length !== 0) {
    return abilitiesStore.can(I, A);
  }
};
