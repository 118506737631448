import React, { useState } from 'react';
import Countdown from 'react-countdown';
import ResendIcon from 'assets/images/ResendIcon.svg';
import ResendIconWhite from 'assets/images/ResendIconWhite.svg';

type Props = {
  duration: number;
  onResend: () => void;
};

export const CountDown: React.FC<Props> = React.memo(({ duration, onResend }) => {
  const [toggleTimer, setToggleTimer] = useState<boolean>(false);

  const startDate = React.useRef(Date.now());
  const renderer = ({
    minutes,
    seconds,
    completed,
  }: {
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
    } else {
      return (
        <div className='flex items-center justify-center gap-3 grow'>
          <img src={ResendIconWhite} className='' />
          <div className='flex flex-col grow'>
            <span>Resend</span>
            <span>
              in {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <button
        className='absolute right-0 top-0 bg-transparent inline-flex items-center justify-center px-2 py-2.5 border border-nell-blue text-[10px] font-heavyGaret font-black rounded-[5px] shadow-sm text-nell-blue disabled:bg-nell-light-grey disabled:text-nell-white disabled:border-nell-white tracking-[1.5px] disabled:cursor-not-allowed focus:outline-none h-12 hover:bg-transparent/90 w-[110px]'
        disabled={toggleTimer}
        onClick={() => {
          setToggleTimer(prev => !prev);
          onResend();
        }}
      >
        {toggleTimer ? (
          <Countdown
            date={startDate.current + duration * 1000}
            renderer={renderer}
            onComplete={() => {
              setToggleTimer(false);
              startDate.current = Date.now();
            }}
          />
        ) : (
          <div className='flex items-center justify-center gap-3 '>
            <img src={ResendIcon} className='' />
            <span>Resend</span>
          </div>
        )}
      </button>
    </>
  );
});
