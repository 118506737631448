import React, { useEffect, useCallback } from 'react';

export const useOutsideAlerter = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  onOutsideClick: () => void
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    },
    [ref, onOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return handleClickOutside;
};
