import { Loader } from 'components/shared';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useStore } from 'services/context';
import { Dashboard } from 'screens/dashboard/Dashboard';
import { DashboardLayout } from 'screens/dashboard/DashboardLayout';
import { Login } from 'screens/login';
import { MyProfile } from 'screens/myProfile/MyProfile';
import { SafetyPage } from 'screens/safetyPage/SafetyPage';
import { Settings } from 'screens/settings/Settings';
import ScrollToTop from 'utils/scrollToTop';
import { EditComProfile } from 'screens/settings';
import { Page404 } from 'screens/notFound';
import { LocationProfile, Locations } from 'screens/locations';

interface RouteProps {
  element: JSX.Element;
  children: RouteProps[];
  visible: boolean;
  path: string;
}

const publicRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
];

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [state] = useStore();
  const isAuthenticated: boolean = state.authStore.isLoggedIn || false;
  const isLoading = false;
  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} />;
  }
  return children;
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const [state] = useStore();
  const isAuthenticated: boolean = state.authStore.isLoggedIn || false;

  if (isAuthenticated) {
    return <Navigate to='/dashboard' />;
  }
  return children;
};

const renderNestedRoutes = (routes: RouteProps[]) => {
  return routes
    .filter(route => route.visible)
    .map((route, index) => {
      if (route.children && route.children.length > 0) {
        return (
          <Route
            key={index}
            path={route.path}
            element={<PrivateRoute>{route.element}</PrivateRoute>}
          >
            {renderNestedRoutes(route.children)}
          </Route>
        );
      }
      return (
        <Route
          key={`-${index}-`}
          path={route.path}
          element={<PrivateRoute>{route.element}</PrivateRoute>}
        />
      );
    });
};

const AppRoutes = () => {
  const [state] = useStore();
  const { organizationStore } = state;
  const { isThereCompany } = organizationStore;
  const isActive: boolean = isThereCompany;

  const privateRoutes = [
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      visible: isActive,
      children: [
        {
          path: '/dashboard',
          element: <Dashboard />,
          visible: isActive,
        },
        {
          path: '/dashboard/profile',
          element: <MyProfile />,
          visible: isActive,
        },
        {
          path: '/dashboard/locations',
          element: <Locations />,
          visible: isActive,
        },
        {
          path: '/dashboard/settings',
          element: <Settings />,
          visible: isActive,
        },
        {
          path: '/dashboard/:screen/:type/:id',
          element: <EditComProfile />,
          visible: isActive,
        },
        {
          path: '/dashboard/locations/:id',
          element: <LocationProfile />,
          visible: isActive,
        },
      ],
    },
    {
      path: '/safetypage',
      element: <SafetyPage />,
      visible: !isActive,
    },
  ];

  return (
    <Suspense>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* PUBLIC ROUTES */}
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PublicRoute key={index}>{route.component}</PublicRoute>}
            />
          ))}
          {/* End Public Routes */}

          {/* Start PRIVATE ROUTES */}
          {renderNestedRoutes(privateRoutes as Array<RouteProps>)}
          {/* End Private Routes */}
          <Route path='*' element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
