import React, { useEffect, useState } from 'react';
import { OrganizationServices } from 'services/apis/Organization';
import { UseAbility } from 'services/context';
import { toast } from 'react-toastify';
import { translate } from 'utils/HelperFunctions';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DetailsLessMore from 'components/shared/DetailsLessMore';
import { LocationUsers } from './LocationUsers';
import { Tabs } from 'components/shared';
import { LocationChangeStatus } from './LocationChangeStatus';
import { LocationRoles } from './locationRoles';

export const LocationProfile = () => {
  const navigate = useNavigate();
  const [locationProfile, setLocationProfile] = useState<{ [key: string]: any }>({});
  const [isStatusVisible, setIsStatusVisible] = useState(false);
  const canEditLocationStatus = UseAbility('locationStatus', 'put');
  const canEditLocation = UseAbility('compLocation', 'put');
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'users',
  });
  const [activeKeyTab, setActiveKeyTab] = useState<string>('1');
  const tabParam = searchParams.get('tab');

  useEffect(() => {
    if (!tabParam || tabParam === 'users') {
      setActiveKeyTab('1');
    } else {
      setActiveKeyTab('2');
    }
  }, [tabParam]);

  const _renderTabs = () => {
    switch (activeKeyTab) {
      case '1':
        return <LocationUsers />;
      case '2':
        return <LocationRoles />;
      default:
        return null;
    }
  };

  const fetchLocationProfile = async () => {
    try {
      if (id) {
        setLocationProfile({});
        const locationById = await OrganizationServices.fetchLocationDetailsById(id);
        setLocationProfile(locationById);
        return;
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchLocationProfile();
  }, []);

  const addressObj = locationProfile?.address;
  const addressValues =
    locationProfile?.address &&
    Object.keys(addressObj)
      .filter(key => key !== '' && key.includes('address'))
      .map(key => locationProfile?.address[key])
      .filter(address => address !== '')
      .join(', ');

  const _renderLocationUser = () => {
    return (
      <div className={`relative sm:bg-nell-dark-grey sm:pt-8 rounded-[20px] mb-4`}>
        <Tabs
          activeKey={activeKeyTab}
          onChange={key => {
            setActiveKeyTab(key);
            setSearchParams({ tab: key === '1' ? 'users' : 'groups' });
          }}
          tabPanes={[
            { name: translate('settingsScreen.users'), key: '1' },
            { name: translate('settingsScreen.roles'), key: '2' },
          ]}
        />
        {_renderTabs()}
      </div>
    );
  };
  const _renderLocationInfo = () => {
    return (
      <>
        {isStatusVisible && (
          <LocationChangeStatus
            isStatusVisible={isStatusVisible}
            setIsStatusVisible={setIsStatusVisible}
            locationProfile={locationProfile}
            setLocationProfile={setLocationProfile}
          />
        )}
        <div className='border-0 rounded-[20px] text-nell-white sm:bg-nell-dark-grey py-8 mb-4'>
          <div className='flex items-center justify-end flex-wrap gap-4 px-4'>
            <div className='justify-self-start grow'>
              <span className='xtext-nell-blue font-prototype text-2xl'>
                {locationProfile?.name}
              </span>
            </div>
            <button
              disabled={!canEditLocation}
              onClick={() => navigate(`/dashboard/locations/location/${id}`)}
              className='tw-button !border-2 !w-[130px] sm:!w-[150px] h-14 !rounded-lg '
            >
              {translate('common.edit')}
            </button>
            <button
              disabled={!canEditLocationStatus}
              onClick={() => {
                setIsStatusVisible(!isStatusVisible);
              }}
              className={`tw-button !border-2 !w-[130px] sm:!w-[150px] h-14 !rounded-lg ${
                locationProfile?.status === 'active' ? '!bg-nell-green' : '!bg-nell-red'
              }`}
            >
              {locationProfile?.status === 'active'
                ? translate('common.active')
                : translate('common.inactive')}
            </button>
            <div className='w-full flex gap-4 justify-end pr-10'></div>
          </div>
          <hr className='border-t-1 border-nell-light-grey my-5' />
          <div className='px-4 pb-4'>
            <span className='font-prototype text-2xl'>
              {translate('locationsScreen.locationInfo')} :
            </span>{' '}
          </div>
          <div className='grid grid-cols-2 md:grid-cols-3 gap-6 px-4 break-all whitespace-normal'>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.operatingName')}</div>
              <div className=''>{locationProfile?.profile?.info?.operatingName || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.website')}</div>
              <a
                href={
                  locationProfile?.profile?.info?.website.startsWith('https://') ||
                  locationProfile?.profile?.info?.website.startsWith('http://')
                    ? locationProfile?.profile?.info?.website
                    : `https://${locationProfile?.profile?.info?.website}`
                }
                target='_blank'
              >
                {locationProfile?.profile?.info?.website || '-'}
              </a>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.address')}</div>
              <div className=''>{addressValues || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.city')}</div>
              <div className=''>{locationProfile?.address?.city || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.postalCode')}</div>
              <div className=''>{locationProfile?.address?.postalCode || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.region')}</div>
              <div className=''>{locationProfile?.address?.region || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.country')}</div>
              <div className=''>{locationProfile?.address?.country || '-'}</div>
            </div>
            <div className='space-y-4'>
              <div className='font-heavyGaret'>{translate('settingsScreen.description')}</div>
              <div>
                <div>
                  <DetailsLessMore description={locationProfile?.description} />
                </div>
              </div>
            </div>
          </div>
          <hr className='border-t-1 border-nell-light-grey my-5' />
          <div className='px-4 pb-4'>
            <span className='font-prototype text-2xl'>
              {translate('settingsScreen.contactInfo')} :
            </span>{' '}
          </div>
          <div className='grid grid-cols-2 md:grid-cols-3 gap-6 px-4 '>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.Salutation')}</div>
              <div className=''>{locationProfile?.profile?.contactInfo?.salutation || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret break-words'>
                {translate('settingsScreen.firstName')}{' '}
              </div>
              <div className=''>{locationProfile?.profile?.contactInfo?.firstName || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.lastName')}</div>
              <div className='capitalize'>
                {locationProfile?.profile?.contactInfo?.lastName || '-'}
              </div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.title')}</div>
              <div className=''>{locationProfile?.profile?.contactInfo?.title || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.phoneNumber')}</div>
              <div className=''>
                {locationProfile?.profile?.contactInfo?.phone
                  ? +locationProfile?.profile?.contactInfo?.phone
                  : '-'}
              </div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.email')}</div>
              <div className='break-all'>
                <a href={`mailto:${locationProfile?.profile?.contactInfo?.email}`}>
                  {locationProfile?.profile?.contactInfo?.email || '-'}
                </a>
              </div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.prefferedContact')}</div>
              <div className=''>
                {locationProfile?.profile?.contactInfo?.preferredContact || '-'}
              </div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.prefferedLanguage')}</div>
              <div className=''>
                {locationProfile?.profile?.contactInfo?.preferredLanguage || '-'}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      {_renderLocationInfo()} {_renderLocationUser()}
    </div>
  );
};
