import { useCallback, useEffect, useRef, useState } from 'react';
import Logo from 'assets/images/NellEnterpriseLogo.svg';
import NLogo from 'assets/images/NLogo.png';
import { ReactComponent as BillIcon } from 'assets/images/BillIcon.svg';
import { ReactComponent as BellBlueIcon } from 'assets/images/BellBlueIcon.svg';
import { clearUserKeyACL } from 'utils/StorageServices';
import { createUserAbilities, updateAbilities } from 'services/context/abilities/actions';
import { PureAbility } from '@casl/ability';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Outlet, NavLink, useLocation, useParams, useMatch, useNavigate } from 'react-router-dom';
import { getPathTitle, companySideBarRoutes, locationSideBarRoutes } from './Helpers';
import { TopbarMenu } from './TopBarMenu';
import { BsArrowLeft } from 'react-icons/bs';
import { getExtKey, translate } from 'utils/HelperFunctions';
import { useStore } from 'services/context';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';

export const DashboardLayout = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [state, dispatch] = useStore();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [miniSidebar, setMiniSidebar] = useState<boolean>(true);
  const [bodyHeight, setBodyHeight] = useState<string>('');
  const matchOrganizationEdit = useMatch('/dashboard/settings/:type/:id');
  const matchLocationEdit = useMatch('/dashboard/locations/:type/:id');
  const matchLocationDetails = useMatch('/dashboard/locations/:id');
  const sidebarRef = useRef(null);
  const bodyRef: any = useRef(null);
  const [sideBarTypesRoutes, setSideBarTypesRoutes] = useState<any[]>([]);
  const extKey = getExtKey();

  const {
    organizationStore: {
      selectedCompany: { type },
    },
  } = state;
  const ability = new PureAbility();

  const Aclfunction = async () => {
    if (extKey) {
      clearUserKeyACL();
      await createUserAbilities(ability);
      dispatch(updateAbilities(ability));
    }
  };

  useEffect(() => {
    Aclfunction();
  }, [pathname]);

  useEffect(() => {
    switch (type) {
      case 'company':
        return setSideBarTypesRoutes(companySideBarRoutes());
      case 'location':
        return setSideBarTypesRoutes(locationSideBarRoutes());
      default:
        return;
    }
  }, [type]);

  const handleOutsideClick = useCallback(() => {
    setSidebarOpen(false);
    setMiniSidebar(true);
  }, []);

  useOutsideAlerter(sidebarRef, handleOutsideClick);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 630) {
        setSidebarOpen(false);
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if ((!miniSidebar && window.innerWidth < 775) || (isMobile && sidebarOpen)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    if (bodyRef.current) {
      const height = bodyRef.current.offsetHeight;
      setBodyHeight(`${height}px`);
    }
  }, [miniSidebar, sidebarOpen]);

  const handleGoBack = () => {
    if (search.includes('tab=')) {
      const newPath = pathname.split('/').slice(0, 3).join('/');
      navigate(newPath);
    } else {
      history.back();
    }
  };

  return (
    <div ref={bodyRef}>
      {isMobile && (
        <>
          <div
            className={`${
              sidebarOpen
                ? `absolute top-0 right-0 left-0 xbottom-0 bg-nell-black bg-opacity-90 z-50`
                : 'w-0 left-0'
            } transition-all duration-500 `}
            style={{ bottom: `${bodyHeight ? `calc(100% - ${bodyHeight})` : ''}` }}
          ></div>
          <div
            className={`${
              sidebarOpen ? 'left-0' : '-left-56'
            } w-56 md:flex-col fixed md:inset-y-0 bg-nell-blue h-[100vh] z-50 transition-all duration-500 text-nell-white`}
            ref={sidebarRef}
          >
            <div className='flex-1 flex flex-col min-h-0'>
              <div className='flex-1 flex flex-col gap-6 mt-4 overflow-y-auto overflow-x-hidden'>
                <button
                  type='button'
                  className='ml-6 flex items-center justify-center h-12 w-12 focus:outline-none'
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                >
                  <span className='sr-only'>Close sidebar</span>
                  <XIcon
                    className='h-10 w-10 stroke-[3px] text-white hover:text-primary-light-grey hover:scale-[1.1] focus:outline-none'
                    aria-hidden='true'
                  />
                </button>
                <div className=''>
                  <img src={Logo} className='w-32 mx-auto' />
                </div>
                <nav className='flex flex-col flex-1 justify-center pb-4 space-y-3'>
                  {sideBarTypesRoutes.map(({ name, path, icon }: any, index: number) => {
                    const isActive =
                      pathname === path || pathname === `${path}/${Object?.values?.(params)?.[0]}`;
                    return (
                      <NavLink
                        key={index}
                        to={path}
                        className={`${
                          isActive
                            ? 'bg-nell-white text-nell-black font-black'
                            : 'text-white text-opacity-90 hover:bg-primary-blue/80 hover:bg-white/50'
                        } text-center group flex items-center justify-start pl-4
                        mx-4 py-3 rounded-[20px] tracking-[2px] text-[13px] font-heavyGaret font-black uppercase whitespace-nowrap
                    `}
                        onClick={() => setSidebarOpen(false)}
                      >
                        {icon(isActive)}
                        <span className='pl-4'>{name}</span>
                      </NavLink>
                    );
                  })}
                </nav>
              </div>
              <div className='flex-shrink-0 flex justify-center p-4'>{/* End of sidebar */}</div>
            </div>
          </div>
          <div className='sticky top-0 z-40'>
            <div className='flex items-center justify-between bg-nell-blue px-4 h-16'>
              <button
                type='button'
                className={`${
                  sidebarOpen ? '' : ''
                } h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-100 hover:text-gray-400 transition-all duration-500`}
                onClick={() => setSidebarOpen(true)}
              >
                <span className='sr-only'>{translate('common.closeSideBar')}</span>
                <MenuIcon className='h-9 w-9 focus:outline-none' aria-hidden='true' />
              </button>
              <div className='pl-12'>
                <img src={NLogo} className='w-12 h-12' />
              </div>
              <div className={`flex items-center justify-end transition-all duration-500`}>
                <div className='flex justify-center items-center w-full'>
                  <div className='flex items-center justify-between'>
                    <BillIcon className='mr-4 cursor-pointer' />
                    <TopbarMenu />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex items-center  mt-0 gap-5 flex-shrink-0 text-nell-white text-2xl pl-4 bg-nell-black ${
                getPathTitle(pathname) !== 'settings' ? 'py-4' : 'pt-0'
              }`}
            >
              {(matchOrganizationEdit || matchLocationEdit || matchLocationDetails) && (
                <BsArrowLeft className='cursor-pointer' onClick={handleGoBack} />
              )}
              <span className={`capitalize`}>{getPathTitle(pathname)}</span>
            </div>
          </div>

          <div
            className={`main ${
              sidebarOpen ? 'lg:pl-44' : 'pl-0'
            } pr-0 flex flex-col flex-1 transition-all duration-500`}
          >
            <main className='flex-1 bg-primary-dark'>
              <div className=''>
                <div className='mx-auto'>
                  {/* Replace with your content */}
                  <Outlet />
                  {/* /End replace */}
                </div>
              </div>
            </main>
          </div>
        </>
      )}
      {!isMobile && !sidebarOpen && (
        <>
          <div
            className={`${
              !miniSidebar && window.innerWidth <= 775
                ? `absolute top-0 right-0 left-0 xbottom-0 bg-nell-black bg-opacity-90 z-50`
                : 'w-0 left-0'
            } transition-all duration-500 `}
            style={{ bottom: `${bodyHeight ? `calc(100% - ${bodyHeight})` : ''}` }}
          ></div>
          <div
            className={`left-4 ${
              miniSidebar ? 'w-[88px]' : 'w-56'
            } md:flex-col fixed md:inset-y-0 bg-nell-blue rounded-2xl mt-4 h-[96vh] z-50 transition-all duration-500 text-nell-white`}
            ref={sidebarRef}
          >
            <div className='flex-1 flex flex-col min-h-0'>
              <div className='flex-1 flex flex-col gap-6 mt-6 overflow-y-auto overflow-x-hidden'>
                <button
                  type='button'
                  className={`flex items-center xjustify-center h-12 ${
                    miniSidebar ? 'xw-full w-12 ml-6' : 'w-12 ml-6'
                  } focus:outline-none transition-all`}
                  onClick={() => {
                    setMiniSidebar(prev => !prev);
                  }}
                >
                  <span className='sr-only'>{translate('common.closeSideBar')}</span>
                  <MenuIcon
                    className='h-10 w-10 stroke-[3px] text-white hover:text-primary-light-grey hover:scale-[1.1] focus:outline-none'
                    aria-hidden='true'
                  />
                </button>
                <div className=''>
                  {miniSidebar ? (
                    <img src={NLogo} className='w-14 mx-auto' />
                  ) : (
                    <img src={Logo} className='w-32 mx-auto' />
                  )}
                </div>
                <nav className='flex flex-col flex-1 justify-center pb-4 space-y-3 '>
                  {sideBarTypesRoutes.map(({ name, path, icon }: any, index: number) => {
                    const isActive =
                      pathname === path || pathname === `${path}/${Object?.values?.(params)?.[0]}`;
                    return (
                      <NavLink
                        key={index}
                        to={path}
                        className={`${
                          isActive
                            ? 'bg-nell-white text-nell-black font-black'
                            : 'text-white text-opacity-90 hover:bg-primary-blue/80 hover:bg-white/50'
                        } text-center group flex items-center ${
                          miniSidebar ? 'justify-center' : 'justify-start pl-4'
                        } mx-4 py-3 rounded-[20px] tracking-[0.5px] font-heavyGaret text-[14px] uppercase font-black whitespace-nowrap overflow-hidden
                    `}
                      >
                        {icon(isActive)}
                        {!miniSidebar && <span className='pl-4'>{name}</span>}
                      </NavLink>
                    );
                  })}
                </nav>
              </div>
              <div className='flex-shrink-0 flex justify-center p-4'>{/* End of sidebar */}</div>
            </div>
          </div>
          <div className='sticky bg-nell-black top-0 z-40 pt-4 pb-4 pr-4'>
            <div className='flex items-start'>
              <div
                className={`${
                  miniSidebar ? 'ml-[130px]' : 'ml-[275px]'
                } flex items-center justify-between w-full transition-all duration-500 h-13`}
              >
                <div className='flex items-center gap-5 flex-shrink-0 text-nell-white text-2xl'>
                  {(matchOrganizationEdit || matchLocationEdit || matchLocationDetails) && (
                    <BsArrowLeft className='cursor-pointer' onClick={handleGoBack} />
                  )}
                  <span className='!capitalize'>{getPathTitle(pathname)}</span>
                </div>
                <div className='flex items-center'>
                  <BellBlueIcon className='text-nell-blue mr-5 cursor-pointer' />
                  <TopbarMenu />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`main ${
              miniSidebar ? 'pl-[120px]' : 'pl-64'
            } text-nell-white pr-4 flex flex-col flex-1 transition-all duration-500`}
          >
            <main className='flex-1'>
              <div className=''>
                <div className='mx-auto'>
                  {/* Replace with your content */}
                  <Outlet />
                  {/* /End replace */}
                </div>
              </div>
            </main>
          </div>
        </>
      )}
    </div>
  );
};
