import axios from 'axios';
import { getExtKey } from 'utils/HelperFunctions';
import { ChangePasswordProperties, User, UserAccountProperties } from '../Auth/auth.types';
import {
  getStorageUserData,
  getUserDataByKey,
  getUserKeyACL,
  setStorageUserData,
  setUserKeyACL,
} from 'utils/StorageServices';
import { updateUserAccount } from 'services/context/auth/Actions';
import store from 'services/context/store';

export const UracServices = {
  //Used APIS
  forgotPasswordCode: async (username: string): Promise<any> => {
    const endPoint = `urac/password/forgot/code?username=${username}`;
    try {
      const forgotCode = await axios.get(endPoint);
      return forgotCode;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (payload: any): Promise<any> => {
    const endPoint = `urac/password/reset`;
    try {
      const resetPassword = await axios.put(endPoint, payload);
      return resetPassword;
    } catch (error) {
      throw error;
    }
  },
  fetchUser: async (): Promise<any> => {
    const endPoint = `urac/user/me`;
    try {
      const uracUser = await axios.get<object, User>(endPoint);
      setStorageUserData(uracUser);
      store.dispatch(updateUserAccount(uracUser));
      return uracUser;
    } catch (error) {
      throw error;
    }
  },
  updateAccount: async (payload: Partial<UserAccountProperties>): Promise<any> => {
    const endPoint = `urac/account`;
    try {
      const id: string = getUserDataByKey('_id');
      const userData = await axios.put(endPoint, {
        id,
        ...payload,
      });
      const prevUserData = getStorageUserData();

      const newData = { ...userData, ...prevUserData, ...payload };

      setStorageUserData(newData);
      store.dispatch(updateUserAccount(newData));

      return newData;
    } catch (error) {
      throw error;
    }
  },
  changeEmail: async (email: string): Promise<any> => {
    const endPoint = `urac/account/email/code`;
    const id: string = getUserDataByKey('_id');
    try {
      const response = await axios.put(endPoint, { email, id });
      return response;
    } catch (error) {
      throw error;
    }
  },
  validateChangeEmailCode: async (code: string): Promise<any> => {
    const endPoint = `urac/validate/changeEmail?token=${code}`;
    try {
      const response = await axios.get(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
  changePassword: async (payload: ChangePasswordProperties): Promise<any> => {
    const endPoint = `urac/account/password`;
    try {
      const id: string = getUserDataByKey('_id');
      await axios.put(endPoint, { id: id, ...payload });
      return true;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyGroups: async (): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `urac/admin/groups`;
      const companyGroups = await axios.get(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return companyGroups;
    } catch (error) {
      throw error;
    }
  },
  fetchAdminGroupDetail: async (groupId: string): Promise<any> => {
    try {
      const endPoint = `urac/admin/group?id=${groupId}`;
      const extKey = getExtKey();
      const detail = await axios.get(endPoint, {
        headers: {
          key: extKey,
        },
      });

      return detail;
    } catch (error) {
      throw error;
    }
  },
  addAdminGroup: async (payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `urac/admin/group`;
      const adminGroups = await axios.post(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return adminGroups;
    } catch (error) {
      throw error;
    }
  },
  editAdminGroup: async (payload: any): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `urac/admin/group`;
      const adminGroups = await axios.put(endPoint, payload, {
        headers: {
          key: extKey,
        },
      });
      return adminGroups;
    } catch (error) {
      throw error;
    }
  },
  deleteAdminGroup: async (id: string): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `urac/admin/group?id=${id}`;
      const adminGroups = await axios.delete(endPoint, {
        headers: {
          key: extKey,
        },
      });
      return adminGroups;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyById: async (companyId: string): Promise<any> => {
    try {
      const endPoint = `urac/users/company?id=${companyId}`;
      const response = await axios.get(endPoint);
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchCompanyUsers: async (): Promise<any> => {
    try {
      const extKey = getExtKey();
      const endPoint = `urac/api-v2/admin/users`;
      const companyUsers = await axios.get(endPoint, {
        params: { scope: 'otherTenancyInvited', config: true },
        headers: {
          key: extKey,
        },
      });
      return companyUsers;
    } catch (error) {
      throw error;
    }
  },
  getUserACL: async (extKey?: any) => {
    let cacheIndex = '0000000000000000';
    if (extKey) {
      cacheIndex = extKey.substr(extKey.length - 16);
    }

    const acl_cached = getUserKeyACL(cacheIndex);
    if (acl_cached) {
      return acl_cached;
    }
    let opt: any = null;
    if (extKey) {
      opt = {
        headers: { key: extKey },
      };
    }
    const aclKey = await axios.get('/soajs/acl', opt);
    if (aclKey) {
      setUserKeyACL(aclKey, cacheIndex);
    }
    return aclKey;
  },
};
