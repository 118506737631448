import { ICompanyProperties } from 'services/apis/Organization/Organization.types';

export const actions: Record<string, string> = {
  UPDATE_COMPANIES: 'UPDATE_COMPANIES',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATE_PENDING_CONNECT_COMPANIES: 'UPDATE_PENDING_CONNECT_COMPANIES',
};

export const updateCompanies = (companies: any[]) => ({
  type: actions.UPDATE_COMPANIES,
  payload: companies,
});

export const updateCompany = (company: ICompanyProperties) => ({
  type: actions.UPDATE_COMPANY,
  payload: company,
});

export const updatePendingConnectCompanies = (connectCompanies: Array<any>) => ({
  type: actions.UPDATE_PENDING_CONNECT_COMPANIES,
  payload: connectCompanies,
});
