import { translate } from 'utils/HelperFunctions';
import { ReactComponent as DashboardIcon } from 'assets/images/DashboardNavIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/SettingsNavIcon.svg';
import { CiLocationOn } from 'react-icons/ci';
export type ISidebarRoute = {
  name: string;
  path: string;
  icon: (isActive: boolean) => JSX.Element;
};

export const companySideBarRoutes = (): ISidebarRoute[] => [
  {
    name: translate('common.dashboard'),
    path: '/dashboard',
    icon: isActive => (
      <DashboardIcon fill={isActive ? '#003B3F' : '#F1E5DD'} className={`flex-shrink-0 h-6 w-6`} />
    ),
  },
  {
    name: translate('common.locations'),
    path: '/dashboard/locations',
    icon: isActive => (
      <CiLocationOn fill={isActive ? '#003B3F' : '#F1E5DD'} className={`flex-shrink-0 h-6 w-6`} />
    ),
  },
  {
    name: translate('common.settings'),
    path: '/dashboard/settings',
    icon: isActive => (
      <SettingsIcon fill={isActive ? '#003B3F' : '#F1E5DD'} className={`flex-shrink-0 h-6 w-6`} />
    ),
  },
];

export const locationSideBarRoutes = (): ISidebarRoute[] => [
  {
    name: translate('common.dashboard'),
    path: '/dashboard',
    icon: isActive => (
      <DashboardIcon fill={isActive ? '#003B3F' : '#F1E5DD'} className={`flex-shrink-0 h-6 w-6`} />
    ),
  },
  {
    name: translate('common.settings'),
    path: '/dashboard/settings',
    icon: isActive => (
      <SettingsIcon fill={isActive ? '#003B3F' : '#F1E5DD'} className={`flex-shrink-0 h-6 w-6`} />
    ),
  },
];

export const getPathTitle = (pathname: string): string | JSX.Element => {
  try {
    const title: string = pathname.split('/')[2] || 'dashboard';
    return translate(`common.${title}`);
  } catch (error) {
    return translate('common.dashboard');
  }
};
