import React, { useEffect, useState } from 'react';
import { OrganizationServices } from 'services/apis/Organization';
import { UseAbility, useStore } from 'services/context';
import { toast } from 'react-toastify';
import { translate } from 'utils/HelperFunctions';
import { useNavigate } from 'react-router-dom';
import DetailsLessMore from 'components/shared/DetailsLessMore';
export const CompanyProfile = () => {
  const navigate = useNavigate();
  const [companyProfile, setCompanyProfile] = useState<{ [key: string]: any }>({});
  const [state] = useStore();
  const canEditLocation = UseAbility('location', 'put');
  const canEditCompany = UseAbility('company', 'put');
  const {
    organizationStore: {
      selectedCompany: { _id, type },
    },
  } = state;

  const fetchCompanyProfile = async () => {
    try {
      if (_id) {
        setCompanyProfile({});
        const organizationById =
          type === 'location'
            ? await OrganizationServices.fetchLocationById(_id)
            : type === 'company'
            ? await OrganizationServices.fetchCompanyById(_id)
            : '';
        setCompanyProfile(organizationById);
        return;
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchCompanyProfile();
  }, []);

  const addressObj = companyProfile?.address;
  const addressValues =
    companyProfile?.address &&
    Object.keys(addressObj)
      .filter(key => key !== '' && key.includes('address'))
      .map(key => companyProfile?.address[key])
      .filter(address => address !== '')
      .join(', ');

  const _renderCompanyInfo = () => {
    return (
      <>
        <div className='border-0 rounded-[20px] text-nell-white sm:bg-nell-dark-grey py-8 mb-4'>
          <div className='flex items-center justify-end flex-wrap gap-4 px-4'>
            <div className='justify-self-start grow'>
              <span className='xtext-nell-blue font-prototype text-2xl'>
                {companyProfile?.name}
              </span>
            </div>
            <button
              disabled={
                (type === 'location' && !canEditLocation) || (type === 'company' && !canEditCompany)
              }
              onClick={() => navigate(`/dashboard/settings/${type}/${_id}`)}
              className='tw-button !border-2 !w-[130px] sm:!w-[150px] h-14 !rounded-lg '
            >
              {translate('common.edit')}
            </button>
            <div className='w-full flex gap-4 justify-end pr-10'></div>
          </div>
          <hr className='border-t-1 border-nell-light-grey my-5' />
          <div className='px-4 pb-4'>
            <span className='font-prototype text-2xl'>
              {type === 'company'
                ? translate('settingsScreen.companyInfo')
                : translate('locationsScreen.locationInfo')}{' '}
              :
            </span>{' '}
          </div>
          <div className='grid grid-cols-2 md:grid-cols-3 gap-6 px-4 break-all whitespace-normal'>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.operatingName')}</div>
              <div className=''>{companyProfile?.profile?.info?.operatingName || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.website')}</div>
              <a
                href={
                  companyProfile?.profile?.info?.website.startsWith('https://') ||
                  companyProfile?.profile?.info?.website.startsWith('http://')
                    ? companyProfile?.profile?.info?.website
                    : `https://${companyProfile?.profile?.info?.website}`
                }
                target='_blank'
              >
                {companyProfile?.profile?.info?.website || '-'}
              </a>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.address')}</div>
              <div className=''>{addressValues || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.city')}</div>
              <div className=''>{companyProfile?.address?.city || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.postalCode')}</div>
              <div className=''>{companyProfile?.address?.postalCode || '-'}</div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.region')}</div>
              <div className=''>{companyProfile?.address?.region || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.country')}</div>
              <div className=''>{companyProfile?.address?.country || '-'}</div>
            </div>
            <div className='space-y-4'>
              <div className='font-heavyGaret'>{translate('settingsScreen.description')}</div>
              <div>
                <div>
                  <DetailsLessMore description={companyProfile?.description} />
                </div>
              </div>
            </div>
          </div>
          <hr className='border-t-1 border-nell-light-grey my-5' />
          <div className='px-4 pb-4'>
            <span className='font-prototype text-2xl'>
              {translate('settingsScreen.contactInfo')} :
            </span>{' '}
          </div>
          <div className='grid grid-cols-2 md:grid-cols-3 gap-6 px-4 '>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.Salutation')}</div>
              <div className=''>{companyProfile?.profile?.contactInfo?.salutation || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret break-words'>
                {translate('settingsScreen.firstName')}{' '}
              </div>
              <div className=''>{companyProfile?.profile?.contactInfo?.firstName || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.lastName')}</div>
              <div className='capitalize'>
                {companyProfile?.profile?.contactInfo?.lastName || '-'}
              </div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.title')}</div>
              <div className=''>{companyProfile?.profile?.contactInfo?.title || '-'}</div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.phoneNumber')}</div>
              <div className=''>
                {companyProfile?.profile?.contactInfo?.phone
                  ? +companyProfile?.profile?.contactInfo?.phone
                  : '-'}
              </div>
            </div>
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.email')}</div>
              <div className='break-all'>
                <a href={`mailto:${companyProfile?.profile?.contactInfo?.email}`}>
                  {companyProfile?.profile?.contactInfo?.email || '-'}
                </a>
              </div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.prefferedContact')}</div>
              <div className=''>
                {companyProfile?.profile?.contactInfo?.preferredContact || '-'}
              </div>
            </div>{' '}
            <div className='space-y-2'>
              <div className='font-heavyGaret'>{translate('settingsScreen.prefferedLanguage')}</div>
              <div className=''>
                {companyProfile?.profile?.contactInfo?.preferredLanguage || '-'}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return <div>{_renderCompanyInfo()}</div>;
};
