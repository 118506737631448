import { ReAuthData } from 'services/apis/Auth';
import { ICompany } from 'services/apis/Organization/Organization.types';

export const getUserDataByKey = (key: string) => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    return userData[key] || undefined;
  } catch (error) {
    return null;
  }
};

export const StorageHelper = {
  setSelectedCompany: (company: ICompany): void => {
    try {
      localStorage.setItem('selectedCompany', JSON.stringify(company));
    } catch (e) {}
  },
  getSelectedCompany: (): ICompany => {
    return JSON.parse(localStorage.getItem('selectedCompany') || '{}');
  },
  getPendingConnect: (): boolean => {
    return JSON.parse(localStorage.getItem('isTherePendingConnect') || 'false');
  },
  setPendingConnect: (isTherePendingConnect: boolean): void => {
    localStorage.setItem('isTherePendingConnect', `${isTherePendingConnect}`);
  },
  setIsCompanyProfileCompleted: (isCompleted: boolean): void => {
    localStorage.setItem('isCompanyProfileCompleted', `${isCompleted}`);
  },
  getIsCompanyProfileCompleted: (): boolean => {
    return JSON.parse(localStorage.getItem('isCompanyProfileCompleted') || 'false');
  },
};

export const setUserKeyACL = (acl: any, index: any) => {
  let acl_cached: any = localStorage.getItem('userACL');
  if (!acl_cached) {
    acl_cached = {};
  } else {
    acl_cached = JSON.parse(acl_cached);
  }
  acl_cached[index] = acl;
  localStorage.setItem('userACL', JSON.stringify(acl_cached));
};

export const getUserKeyACL = (index: any) => {
  let acl_cached: any = localStorage.getItem('userACL');
  if (acl_cached) {
    acl_cached = JSON.parse(acl_cached);
    if (acl_cached[index]) {
      return acl_cached[index];
    }
  }
  return null;
};

export const clearUserKeyACL = () => {
  localStorage.removeItem('userACL');
};

export const getStorageLoginData = (key?: string | null) => {
  try {
    const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');
    if (key) {
      return loginData[key] || undefined;
    }
    return loginData;
  } catch (error) {
    return null;
  }
};

export const setStorageLoginData = (data: ReAuthData) => {
  localStorage.setItem('loginData', JSON.stringify({ ...data }));
};

export const getStorageUserData = () => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    return userData;
  } catch (error) {
    return null;
  }
};

export const setStorageUserData = (user: any) => {
  localStorage.setItem('userData', JSON.stringify(user));
};
