import { Modal, Spiner } from 'components/shared';
import { useParams } from 'react-router-dom';
import { OrganizationServices } from 'services/apis/Organization';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { translate } from 'utils/HelperFunctions';

type Props = {
  locationProfile: { [key: string]: any };
  setLocationProfile: (company: any) => void;
  isStatusVisible: boolean;
  setIsStatusVisible: (isStatusVisible: boolean) => void;
};
export const LocationChangeStatus = ({
  locationProfile,
  setLocationProfile,
  isStatusVisible,
  setIsStatusVisible,
}: Props) => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);

  const ChangeStatus = async () => {
    try {
      setLoading(true);
      if (id) {
        await OrganizationServices.ChangeLocationStatus(id, {
          ...locationProfile,
          status: `${locationProfile?.status === 'active' ? 'inactive' : 'active'}`,
        });
        const company = await OrganizationServices.fetchLocationDetailsById(id);
        setLocationProfile(company);
      }
      setLoading(false);
      setIsStatusVisible(false);
      toast.success(translate('locationsScreen.statusUpdatedSuccessfully'));
    } catch (error: any) {
      setLoading(false);
      toast.error(error.errors?.details?.[0]?.message);
      throw error;
    }
  };
  return (
    <Modal
      visible={isStatusVisible}
      onClose={() => {
        setIsStatusVisible(false);
      }}
      modalTitle={translate('settingsScreen.changeStatus')}
    >
      <div className={`w-full flex-col gap-2 justify-center px-4 md:px-8`}>
        <div>
          {translate('locationsScreen.areYouSureYouWantToChangeTheStatusTo')}{' '}
          {locationProfile?.status === 'active' ? (
            <p className='text-nell-red font-heavyGaret'>{translate('common.inactive')}?</p>
          ) : (
            <p className='text-nell-green font-heavyGaret'>{translate('common.active')}?</p>
          )}
        </div>
        <div className='w-full flex gap-4 justify-end'>
          <button
            type='button'
            className='tw-button mt-5 !w-[130px] !h-[38px] md:!w-[150px] md:!h-12'
            onClick={() => {
              setIsStatusVisible(false);
            }}
          >
            {translate('common.cancel')}
          </button>
          <button
            type='button'
            className='tw-button mt-5 !w-[130px] !h-[38px] md:!w-[150px] md:!h-12'
            onClick={ChangeStatus}
          >
            {isLoading && <Spiner className='!h-3 !w-3 md:!h-5 md:!w-5 ml-[120px] md:ml-[126px]' />}
            {translate('common.confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
