import { Modal, Spiner } from 'components/shared';
import React from 'react';
import { translate } from 'utils/HelperFunctions';
type Props = {
  isLoading: boolean;
  isPasswordVisible: boolean;
  setIsPasswordVisible: (visible: boolean) => void;
  newPassword: { [key: string]: any };
  setNewPassword: (e: any) => void;
  onSaveChangePassword: () => void;
};

export const ChangePassword = (props: Props) => {
  const {
    isLoading,
    isPasswordVisible,
    setIsPasswordVisible,
    newPassword,
    setNewPassword,
    onSaveChangePassword,
  } = props;

  const inputTypes = [
    {
      name: 'oldPassword',
      placeholder: translate('profileScreen.currentPassword'),
      label: translate('profileScreen.currentPasswordLabel'),
    },
    {
      name: 'password',
      placeholder: translate('profileScreen.newPassword'),
      label: translate('profileScreen.newPasswordLabel'),
    },
    {
      name: 'confirmation',
      placeholder: translate('profileScreen.confirmNewPassword'),
      label: translate('profileScreen.confirmNewPasswordLabel'),
    },
  ];

  return (
    <Modal
      visible={isPasswordVisible}
      onClose={() => {
        setIsPasswordVisible(false);
        setNewPassword({
          password: '',
          oldPassword: '',
          confirmation: '',
        });
      }}
      modalTitle={translate('profileScreen.changePassword')}
    >
      <div className='flex flex-col space-y-4 mb-6 px-4  md:px-8'>
        {inputTypes.map(({ name, placeholder, label }, index) => (
          <div key={index}>
            <div className='mt-1 flex flex-col gap-2'>
              <label htmlFor={name} className='text-sm text-nell-blue'>
                {label}
              </label>
              <input
                placeholder={placeholder}
                type={'password'}
                name={name}
                id={name}
                value={newPassword[name]}
                onChange={e =>
                  setNewPassword({
                    ...newPassword,
                    [name]: e.target.value,
                  })
                }
                className='tw-input'
                style={{ textAlign: 'left' }}
              />
            </div>
          </div>
        ))}
        <div className='w-full'>
          <button
            type='button'
            disabled={
              isLoading ||
              newPassword.password === '' ||
              newPassword.oldPassword === '' ||
              newPassword.confirmation === '' ||
              newPassword.password !== newPassword.confirmation
            }
            className='tw-button mt-5 !w-full'
            onClick={onSaveChangePassword}
          >
            {isLoading && <Spiner className='ml-60 md:ml-64' />}
            {translate('profileScreen.changePassword')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
