import { useEffect, useState } from 'react';
import { translate } from 'utils/HelperFunctions';
import { CustomCheckbox, Modal, Option, Spiner, Table } from 'components/shared';
import { UracServices } from 'services/apis/Urac';
import { toast } from 'react-toastify';
import { OrganizationServices } from 'services/apis/Organization';
import { BsTrash, BsPen, BsPlus, BsThreeDotsVertical } from 'react-icons/bs';
import { Menu } from '@headlessui/react';
import { UseAbility, useStore } from 'services/context';

export const Roles = () => {
  const [adminGroups, setAdminGroups] = useState<any[]>([]);
  const [selectedGroupInfo, setselectedGroupInfo] = useState<any>({});
  const [selectedGroupPackages, setselectedGroupPackages] = useState<any>([]);
  const [groupProduct, setGroupProduct] = useState<any>({});
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [isVisibleGroupInfo, setIsVisibleGroupInfo] = useState<boolean>(false);
  const [isVisibleAddEditGroup, setIsVisibleAddEditGroup] = useState<boolean>(false);
  const [isEditableGroup, setIsEditableGroup] = useState<boolean>(false);
  const [isVisibleDeleteGroup, setIsVisibleDeleteGroup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [state] = useStore();
  const canDeleteRole = UseAbility('adminGroup', 'delete');
  const canPostRole = UseAbility('adminGroup', 'post');
  const canPutRole = UseAbility('adminGroup', 'put');

  const {
    organizationStore: {
      selectedCompany: { type },
    },
  } = state;

  const fetchOrganizationRoles = async () => {
    try {
      const roles =
        type === 'company'
          ? await OrganizationServices.fetchCompanyGroups()
          : type === 'location'
          ? await OrganizationServices.fetchLocationGroups()
          : '';
      setAdminGroups(roles);
    } catch (err) {
      throw err;
    }
  };

  const fetchGroupProduct = async () => {
    try {
      const product = await OrganizationServices.fetchGroupProducts();
      setGroupProduct(product);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchOrganizationRoles();
  }, []);

  useEffect(() => {
    fetchGroupProduct();
  }, []);

  const fetchGroupDetails = async (item: any) => {
    setSelectedGroup(item);
    if (item._id) {
      setIsLoading(true);
      try {
        setIsVisibleGroupInfo(true);
        const detail = await UracServices.fetchAdminGroupDetail(item._id);
        setselectedGroupInfo(detail);

        const obj = detail?.config?.allowedPackages || {};
        let allowedPackages: any[] = [];
        Object.keys(obj).map(key => {
          allowedPackages = obj[key];
        });

        const filterOptions: any[] = [];
        groupProduct?.packages?.map((opt: any) => {
          allowedPackages?.map((val: any) => {
            if (val === opt.code) {
              filterOptions.push(opt);
            }
          });
        });
        setselectedGroupPackages(filterOptions);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const onAddNewGroup = async () => {
    if (
      /^\w[a-zA-Z0-9]*$/g.test(selectedGroup.name) &&
      selectedGroup?.packages?.[0]?.packages?.length > 0 &&
      !!selectedGroup.name &&
      !!selectedGroup.description
    ) {
      setIsLoading(true);
      try {
        await UracServices.addAdminGroup({ ...selectedGroup, code: selectedGroup.name });
        setIsLoading(false);
        setIsVisibleAddEditGroup(false);
        toast.success(translate('settingsScreen.roleAddedSuccessfully'));
        setSelectedGroup({});
        fetchOrganizationRoles();
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error?.errros?.details?.[0]?.message);
      }
    } else {
      toast.error(translate('settingsScreen.inValidRoleText'));
    }
  };

  const onEditGroup = async () => {
    if (
      /^\w[a-zA-Z0-9]*$/g.test(selectedGroup.name) &&
      selectedGroup?.packages?.[0]?.packages?.length > 0 &&
      !!selectedGroup.name &&
      !!selectedGroup.description
    ) {
      setIsLoading(true);
      try {
        await UracServices.editAdminGroup({
          ...selectedGroup,
          id: selectedGroup._id,
          code: selectedGroup.name,
        });
        setIsLoading(false);
        setIsVisibleAddEditGroup(false);
        setIsEditableGroup(false);
        toast.success(translate('settingsScreen.roleUpdatedSuccessfully'));
        setSelectedGroup({});
        setselectedGroupPackages([]);
        fetchOrganizationRoles();
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error?.message);
      }
    } else {
      toast.error(translate('settingsScreen.inValidRoleText'));
    }
  };

  const onDeleteGroup = async () => {
    setIsLoading(true);
    if (selectedGroup) {
      try {
        await UracServices.deleteAdminGroup(selectedGroup._id);
        setIsLoading(false);
        toast.success(translate('settingsScreen.roleRemovedSuccessfully'));
        setIsVisibleDeleteGroup(false);
        setSelectedGroup({});
        fetchOrganizationRoles();
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const disableButton = () => {
    if (selectedGroup) {
      const original = adminGroups.filter(item => item._id === selectedGroup?._id);
      const areArraysEqualInEditRole = (arr1: string[], arr2: string[]) => {
        if (arr1?.length !== arr2?.length) {
          return false;
        }
        return arr1?.every((word: string) => arr2?.includes(word));
      };

      return (
        selectedGroup?.name === original?.[0]?.name &&
        selectedGroup?.description === original?.[0]?.description &&
        areArraysEqualInEditRole(selectedGroup?.packages?.[0]?.packages, selectedGroupPackages)
      );
    }
  };
  const onSelectGroup = (item: any) => {
    setSelectedGroup(item);
    const obj = item?.config?.allowedPackages || {};
    let selectedPackages: any[] = [];
    Object.keys(obj).map(key => {
      selectedPackages = obj[key];
    });

    setselectedGroupPackages(selectedPackages);
    setIsEditableGroup(true);
    setIsVisibleAddEditGroup(true);
  };

  const _renderSelectedGroupInfo = () => {
    return (
      <Modal
        visible={isVisibleGroupInfo}
        modalTitle={translate('settingsScreen.roleInfo')}
        onClose={() => {
          setIsVisibleGroupInfo(false);
          setSelectedGroup({});
          setselectedGroupPackages([]);
        }}
      >
        <div className='px-4 sm:px-6'>
          {isLoading ? (
            <div className='flex justify-center items-center rounded-[30px] bg-black/5 h-96'>
              <Spiner className='w-10 h-10' />
            </div>
          ) : (
            <div className='flex flex-col gap-5 items-start justify-start '>
              <div className='w-full'>
                <div className='font-heavyGaret mb-2'> {translate('common.name')}</div>
                <div className='tw-input-dark'>{selectedGroupInfo.name}</div>
              </div>
              <div className='w-full'>
                <div className='font-heavyGaret mb-2'>{translate('common.description')}</div>
                <div className='tw-input-dark'>{selectedGroupInfo.description}</div>
              </div>
              <div className='w-full'>
                <div className='font-heavyGaret mb-2'>{translate('settingsScreen.packages')}</div>
                <div className='border-2 border-nell-light-grey rounded-[4px] max-h-[240px] overflow-y-auto'>
                  {selectedGroupPackages?.map((product: any) => {
                    return (
                      <div key={product.name} className='px-5 py-5 capitalize'>
                        <div className='text-sm font-bold mb-2'>{product.name}</div>
                        <div>{product.description}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const _renderAddEditGroup = () => {
    return (
      <Modal
        visible={isVisibleAddEditGroup}
        modalTitle={
          isEditableGroup
            ? translate('settingsScreen.editRole')
            : translate('settingsScreen.addRole')
        }
        onClose={() => {
          setIsVisibleAddEditGroup(false);
          setSelectedGroup({});
          setselectedGroupPackages([]);
          setIsLoading(false);
          setIsValid(true);
        }}
      >
        <div className='px-4 sm:px-6 mb-6'>
          <div className='flex flex-col gap-5 items-start justify-start'>
            <div className='w-full'>
              <label htmlFor='name' className='block text-sm text-nell-blue mb-2 capitalize'>
                {translate('common.name')}
              </label>
              <input
                type='text'
                name='name'
                id='name'
                required
                maxLength={20}
                placeholder={translate('settingsScreen.enterName')}
                value={selectedGroup?.name || ''}
                onChange={e => {
                  if (/^\w[a-zA-Z0-9]*$/g.test(e.target.value) || !e.target.value) {
                    setIsValid(true);
                  } else {
                    setIsValid(false);
                  }
                  setSelectedGroup({ ...selectedGroup, name: e.target.value });
                }}
                className='tw-input placeholder:text-left placeholder:!capitalize'
                style={{ textAlign: 'left' }}
              />
              {!isValid && (
                <span className='text-nell-red text-sm'>
                  {translate('settingsScreen.inValidRoleText')}
                </span>
              )}
            </div>
            <div className='w-full'>
              <label htmlFor='name' className='block text-sm text-nell-blue mb-2 capitalize'>
                {translate('settingsScreen.description')}
              </label>
              <textarea
                name='bio'
                id='bio'
                placeholder={translate('settingsScreen.enterDescription')}
                value={selectedGroup?.description || ''}
                onChange={e => setSelectedGroup({ ...selectedGroup, description: e.target.value })}
                className='tw-input min-h-[120px] placeholder:text-left placeholder:!capitalize'
                style={{ textAlign: 'left' }}
              />
            </div>
            <div className='w-full'>
              <div className='mb-2'>{translate('settingsScreen.selectPackagesFromTheList')}</div>
              <div className='border-2 border-nell-light-grey rounded-[4px]'>
                <CustomCheckbox
                  // options={groupProduct?.packages.map((pack:Option) => pack.name === "Default" ? {...pack,disabled:true} : pack))}
                  options={groupProduct?.packages?.map((pack: Option) =>
                    pack.name === 'Default' ? { ...pack, disabled: true } : pack
                  )}
                  selectedPackages={
                    selectedGroupPackages.length === 0
                      ? [...selectedGroupPackages, 'APRTL_DEFAU']
                      : selectedGroupPackages
                  }
                  onChange={(e: any) =>
                    setSelectedGroup({
                      ...selectedGroup,
                      packages: [
                        {
                          ...selectedGroup?.packages?.[0],
                          packages: e,
                          product: groupProduct?.code,
                        },
                      ],
                    })
                  }
                  className='flex flex-col overflow-y-auto max-h-[190px] m-2'
                />
              </div>
            </div>
          </div>
          <div className='flex justify-center mt-10'>
            {isEditableGroup ? (
              <button
                className='tw-button w-full'
                disabled={
                  !canPutRole ||
                  isLoading ||
                  disableButton() ||
                  selectedGroup?.packages?.[0]?.packages?.length === 0
                }
                onClick={() => onEditGroup()}
              >
                {isLoading && <Spiner className='ml-44' />}
                {translate('settingsScreen.saveChanges')}
              </button>
            ) : (
              <button
                className='tw-button w-full'
                disabled={
                  !canPostRole ||
                  isLoading ||
                  !selectedGroup?.name ||
                  !selectedGroup?.description ||
                  selectedGroup.packages?.[0]?.packages?.length === 0
                }
                onClick={() => onAddNewGroup()}
              >
                {isLoading && <Spiner className='ml-44' />}
                {translate('settingsScreen.addRole')}
              </button>
            )}
          </div>
        </div>
      </Modal>
    );
  };

  const _renderDeleteGroupConfirmation = () => (
    <Modal
      visible={isVisibleDeleteGroup}
      modalTitle={translate('settingsScreen.deleteRole')}
      onClose={() => {
        setSelectedGroup({});
        setIsVisibleDeleteGroup(false);
        setIsLoading(false);
      }}
    >
      <div className='px-4'>
        <p className='text-base text-left'>
          {translate('settingsScreen.areYouSureYouWantToDeleteRole')}
        </p>
        <p className='font-heavyGaret text-base text-left font-bold mt-3 capitalize'>
          {selectedGroup?.name}
        </p>
        <div className='flex gap-x-3 items-center justify-end mt-6'>
          <div className=''>
            <button
              className='tw-button !bg-transparent !border-nell-blue !text-nell-blue'
              onClick={() => {
                setSelectedGroup({});
                setIsVisibleDeleteGroup(false);
              }}
            >
              <span className='hidden md:block'> {translate('settingsScreen.noKeep')}</span>
              <span className='md:hidden w-20'> {translate('settingsScreen.keep')}</span>
            </button>
          </div>
          <div className=''>
            <button
              disabled={!canDeleteRole}
              className='tw-button !bg-nell-red'
              onClick={() => onDeleteGroup()}
            >
              {isLoading && (
                <Spiner className='!h-4 !w-4 ml-[102px] md:ml-[155px] !text-primary-mint' />
              )}
              <span className='hidden md:block !px-2'>{translate('settingsScreen.yesDelete')}</span>
              <span className='md:hidden w-20'>{translate('settingsScreen.delete')}</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );

  const _renderGroupTable = () => {
    const columns = [
      {
        title: (
          <div className='flex items-center gap-2 cursor-pointer' onClick={() => {}}>
            {translate('common.name')}
          </div>
        ),
        dataIndex: 'name',
        theadClass: 'w-[100px]',
        render: (_: any, item: any) => (
          <div className='flex items-center cursor-pointer'>
            <div
              onClick={() => {
                fetchGroupDetails(item);
              }}
            >
              {item?.name}
            </div>
          </div>
        ),
      },
      {
        title: translate('common.description'),
        dataIndex: 'description',
        render: (_: any, item: any) => (
          <div className='flex items-center cursor-pointer'>
            <div
              onClick={() => {
                fetchGroupDetails(item);
              }}
            >
              {item?.description}
            </div>
          </div>
        ),
      },
      {
        title: translate('common.actions'),
        dataIndex: '',
        align: 'center',
        theadClass: 'w-[150px]',
        render: (_: any, item: any) => {
          return (
            <div className='flex justify-center align-middle gap-4 text-base items-center'>
              <button
                disabled={!canDeleteRole}
                onClick={() => {
                  setSelectedGroup(item);
                  setIsVisibleDeleteGroup(true);
                }}
                className='bg-nell-red rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer hover:filter hover:brightness-110 hover:contrast-110 disabled:cursor-not-allowed'
              >
                <BsTrash />
              </button>
              <button
                onClick={() => {
                  onSelectGroup(item);
                }}
                disabled={!canPutRole}
                className='bg-nell-blue rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer hover:filter hover:brightness-110 hover:contrast-110 disabled:cursor-not-allowed'
              >
                <BsPen />
              </button>
            </div>
          );
        },
      },
    ];

    const _renderRoleForMobile = () => {
      return adminGroups.map(role => {
        return (
          <div
            key={role._id}
            className='bg-nell-mid-grey hover:bg-nell-light-grey text-nell-white rounded-[8px] p-4'
          >
            <div className='flex justify-between'>
              <h2
                className='font-heavyGaret capitalize cursor-pointer'
                onClick={() => {
                  fetchGroupDetails(role);
                }}
              >
                {role.name}
              </h2>
              <Menu as='div' className='relative'>
                <Menu.Button className=''>
                  <span className='sr-only'>Open options</span>
                  <BsThreeDotsVertical className='text-2xl cursor-pointer' />
                </Menu.Button>
                <Menu.Items
                  className={`absolute flex gap-3 p-2 right-0 top-0 z-10 rounded-md bg-nell-grey-secondary`}
                >
                  <Menu.Item>
                    {() => (
                      <button
                        onClick={() => {
                          setSelectedGroup(role);
                          setIsVisibleDeleteGroup(true);
                        }}
                        disabled={!canDeleteRole}
                        className='bg-nell-red rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer hover:filter hover:brightness-110 hover:contrast-110 disabled:cursor-not-allowed'
                      >
                        <BsTrash />
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {() => (
                      <button
                        onClick={() => {
                          onSelectGroup(role);
                        }}
                        disabled={!canPutRole}
                        className='bg-nell-blue rounded-lg w-8 h-8 flex items-center justify-center cursor-pointer hover:filter hover:brightness-110 hover:contrast-110 disabled:cursor-not-allowed'
                      >
                        <BsPen />
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
            <p
              className='h-[50px] ellipsis cursor-pointer'
              onClick={() => {
                fetchGroupDetails(role);
              }}
            >
              {role.description}
            </p>
          </div>
        );
      });
    };

    return (
      <>
        {_renderSelectedGroupInfo()}
        {_renderAddEditGroup()}
        {_renderDeleteGroupConfirmation()}
        <div className='pb-4 mt-7' style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}>
          <div className='fixed bottom-4 sm:bottom-auto right-6 sm:absolute sm:top-6 '>
            <button
              type='button'
              disabled={!canPostRole}
              className='tw-button !border-0 sm:!border-2 w-14 sm:w-fit h-14 sm:!pr-4 !p-0 !rounded-lg'
              onClick={() => {
                setIsEditableGroup(false);
                setIsVisibleAddEditGroup(true);
              }}
            >
              <BsPlus className='text-5xl' />
              <span className='hidden sm:block'>{translate('settingsScreen.newRole')}</span>
            </button>
          </div>

          <div className='hidden sm:block w-full'>
            <Table columns={columns} dataSource={adminGroups} hover />
          </div>
          <div className='flex flex-col gap-4 sm:hidden px-4'>{_renderRoleForMobile()}</div>
        </div>
      </>
    );
  };

  return <div>{_renderGroupTable()}</div>;
};
