import { CSSProperties } from 'react';
import { getStorageLoginData } from './StorageServices';
import i18next, { StringMap, TOptions } from 'i18next';

export const combineReducers = (slices: any) => (state: any, action: any) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](action, acc[prop]),
    }),
    state
  );

export const translate = (key: string, defaultValue?: string | TOptions<StringMap>) => {
  try {
    return i18next.t(key, {
      defaultValue: defaultValue || '',
    });
  } catch (error: any) {
    return '';
  }
};

export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ');
};

export const checkThenReturn = (
  booleanValue: boolean,
  returnedValue: any,
  falseReturnValue: any = null
): any => {
  if (booleanValue) {
    return returnedValue;
  }
  return falseReturnValue;
};

export const checkIsImage = (url: string): boolean => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

export const getPrivateURI = (imagePath?: string) => {
  let theImage = imagePath;
  try {
    if (theImage && theImage.includes('amazonaws.com/')) {
      const access_token = getStorageLoginData('access_token');
      if (access_token) {
        const id = theImage.split('amazonaws.com/')[1];
        theImage = `${process.env.REACT_APP_URL_SAFE}aws/s3/private/object?id=${id}&key=${process.env.REACT_APP_TENANT}&access_token=${access_token}`;
      }
    }
    return theImage;
  } catch (error: any) {
    console.log('error getPrivateURI', error.message);
  }
};

export const calcPagination = (page = 1, per_page = 5) => {
  return {
    skip: (page - 1) * per_page,
    limit: per_page,
  };
};

export const getExtKey = (): string => {
  const extKey = localStorage.getItem('extKey');
  return extKey || '';
};

export const selectTransStyle: CSSProperties = {
  color: 'white',
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: '3.5px',
  textTransform: 'uppercase',
};

export const checkIsEmail = (email: string): boolean => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g.test(email);
};

export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const getStatusClass = (status: string): string => {
  switch (status) {
    case 'new':
      return '!text-nell-white';
    case 'pending':
      return '!text-nell-yellow';
    case 'active':
      return '!text-nell-green';
    case 'deleted':
    case 'inactive':
      return '!text-nell-red';
    case 'approved':
    case 'complete':
    case 'accepted':
    case 'admin_accepted':
    case 'rejected':
    case 'admin_rejected':
      return '!text-nell-red';
  }
  return '';
};
export const getStatusCardClass = (status: string): string => {
  switch (status) {
    case 'new':
      return 'bg-nell-white';
    case 'pending':
      return 'bg-nell-yellow';
    case 'active':
      return 'bg-nell-green';
    case 'deleted':
    case 'inactive':
      return 'bg-nell-red';
    case 'approved':
    case 'accepted':
    case 'complete':
    case 'admin_accepted':
    case 'rejected':
    case 'admin_rejected':
      return 'bg-nell-red';
  }
  return '';
};

export const checkIsNumbers = (code: string): boolean => {
  return /^[0-9]*$/.test(code);
};
