import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'services/context';

export const Page404 = () => {
  const navigate = useNavigate();
  const [state] = useStore();
  const { authStore } = state;
  useEffect(() => {
    if (authStore.isLoggedIn) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  }, []);
  return <div />;
};
