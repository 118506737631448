import { useEffect, useState } from 'react';
import { translate } from 'utils/HelperFunctions';
import { Table } from 'components/shared';
import { OrganizationServices } from 'services/apis/Organization';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Menu } from '@headlessui/react';
import { useParams } from 'react-router-dom';

export const LocationRoles = () => {
  const [adminGroups, setAdminGroups] = useState<any[]>([]);
  const params = useParams();

  const fetchOrganizationRoles = async (id: string) => {
    try {
      if (id) {
        const roles = await OrganizationServices.fetchLocationRoles(id);
        setAdminGroups(roles);
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (params?.id) {
      fetchOrganizationRoles(params?.id);
    }
  }, []);

  const _renderGroupTable = () => {
    const columns = [
      {
        title: (
          <div className='flex items-center gap-2 cursor-pointer' onClick={() => {}}>
            {translate('common.name')}
          </div>
        ),
        dataIndex: 'name',
        theadClass: 'w-[100px]',
        render: (_: any, item: any) => (
          <div className='flex items-center cursor-pointer'>
            <div>{item?.name}</div>
          </div>
        ),
      },
      {
        title: translate('common.description'),
        dataIndex: 'description',
        render: (_: any, item: any) => (
          <div className='flex items-center cursor-pointer'>
            <div>{item?.description}</div>
          </div>
        ),
      },
    ];

    const _renderRoleForMobile = () => {
      return adminGroups.map(role => {
        return (
          <div
            key={role._id}
            className='bg-nell-mid-grey hover:bg-nell-light-grey text-nell-white rounded-[8px] p-4'
          >
            <div className='flex justify-between'>
              <h2 className='font-heavyGaret capitalize cursor-pointer'>{role.name}</h2>
              <Menu as='div' className='relative'>
                <Menu.Button className=''>
                  <span className='sr-only'>Open options</span>
                  <BsThreeDotsVertical className='text-2xl cursor-pointer' />
                </Menu.Button>
              </Menu>
            </div>
            <p className='h-[50px] ellipsis cursor-pointer'>{role.description}</p>
          </div>
        );
      });
    };

    return (
      <>
        <div className='pb-4 mt-7' style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15266)' }}>
          <div className='hidden sm:block w-full'>
            <Table columns={columns} dataSource={adminGroups} hover />
          </div>
          <div className='flex flex-col gap-4 sm:hidden px-4'>{_renderRoleForMobile()}</div>
        </div>
      </>
    );
  };

  return <div>{_renderGroupTable()}</div>;
};
