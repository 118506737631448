import { CustomRadio, CustomSelect, Spiner } from 'components/shared';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ICompanyProfile, OrganizationServices } from 'services/apis/Organization';
import { useStore } from 'services/context';
import { checkIsEmail, translate } from 'utils/HelperFunctions';
import countriesRegions from 'country-region-data/data.json';
import { getPathTitle } from 'screens/dashboard/Helpers';

export const EditComProfile = () => {
  const [state] = useStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [phoneCode, setPhoneCode] = useState<string>('1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidWebsite, setIsValidWebsite] = useState<boolean>(true);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const { id } = useParams();
  const screenPathname = getPathTitle(pathname);
  const {
    organizationStore: {
      selectedCompany: { type },
    },
  } = state;

  const [companyProfile, setCompanyProfile] = useState<ICompanyProfile>({
    name: '',
    description: '',
    id: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      region: '',
      postalCode: '',
      country: 'United States',
    },
    profile: {
      info: {
        operatingName: '',
        website: '',
      },
      contactInfo: {
        salutation: 'Mr.',
        firstName: '',
        lastName: '',
        title: '',
        phone: '',
        email: '',
        preferredContact: 'phone',
        preferredLanguage: 'english',
      },
    },
  });

  const fetchCompanyProfile = async () => {
    try {
      if (id && id !== 'new') {
        const details =
          screenPathname === 'Locations'
            ? await OrganizationServices?.fetchLocationDetailsById(id)
            : await OrganizationServices?.[
                type === 'company' ? 'fetchCompanyById' : 'fetchLocationById'
              ](id);
        setCompanyProfile({
          ...companyProfile,
          ...details,
          profile: {
            ...details?.profile,
            info: {
              ...details?.profile?.info,
              phone: details?.profile?.info?.phone?.slice(1),
            },
            contactInfo: {
              ...details?.profile?.contactInfo,
              phone: details?.profile?.contactInfo?.phone?.slice(1),
            },
          },
        });
        return;
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchCompanyProfile();
  }, []);

  const onAddUpdateProfile = async () => {
    setIsLoading(true);
    try {
      if (id && id !== 'new') {
        if (screenPathname === 'Locations') {
          await OrganizationServices.onUpdateLocationDetails(id, {
            ...companyProfile,
            address: {
              ...companyProfile.address,
              postalCode: companyProfile?.address?.postalCode.replace(' ', '') || '',
            },
            profile: {
              ...companyProfile.profile,
              contactInfo: {
                ...companyProfile.profile.contactInfo,
                phone: `${phoneCode}${companyProfile?.profile?.contactInfo?.phone || ''}`,
              },
            },
          });
          await OrganizationServices.fetchLocationDetailsById(id);
          toast.success(translate('profileScreen.accountHasBeenUpdatedSuccessfully'));
        } else {
          await OrganizationServices?.[
            type === 'company' ? 'updateCompanyProfile' : 'updateLocationProfile'
          ]({
            ...companyProfile,
            address: {
              ...companyProfile.address,
              postalCode: companyProfile?.address?.postalCode.replace(' ', '') || '',
            },
            profile: {
              ...companyProfile.profile,
              contactInfo: {
                ...companyProfile.profile.contactInfo,
                phone: `${phoneCode}${companyProfile?.profile?.contactInfo?.phone || ''}`,
              },
            },
          });
          await OrganizationServices?.[
            type === 'company' ? 'fetchCompanyById' : 'fetchLocationById'
          ](id);
          toast.success(translate('profileScreen.accountHasBeenUpdatedSuccessfully'));
        }
      } else if (id && id === 'new') {
        await OrganizationServices?.addCompanyLocation('location', {
          ...companyProfile,
          address: {
            ...companyProfile.address,
            postalCode: companyProfile?.address?.postalCode.replace(' ', '') || '',
          },
          profile: {
            ...companyProfile.profile,
            contactInfo: {
              ...companyProfile.profile.contactInfo,
              phone: `${phoneCode}${companyProfile?.profile?.contactInfo?.phone || ''}`,
            },
          },
        });
        toast.success(translate('locationsScreen.locationCreatedSuccessfully'));
      }
      setIsLoading(false);
      navigate(-1);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = () => {
    return (
      !!companyProfile.name &&
      !!companyProfile.description &&
      !!companyProfile.address.address1 &&
      !!companyProfile.address.city &&
      !!companyProfile.address.region &&
      !!companyProfile.address.country &&
      !!companyProfile.profile.contactInfo.salutation &&
      !!companyProfile.profile.contactInfo.firstName &&
      !!companyProfile.profile.contactInfo.lastName &&
      !!companyProfile.profile.contactInfo.title &&
      !!companyProfile.profile.contactInfo.phone &&
      !!companyProfile.profile.contactInfo.email &&
      !!companyProfile.profile.contactInfo.preferredContact &&
      !!companyProfile.profile.contactInfo.preferredLanguage &&
      (!!companyProfile?.profile?.info?.website ? isValidWebsite : true) &&
      checkIsEmail(companyProfile.profile.contactInfo.email)
    );
  };

  const _renderInfo = () => {
    return (
      <div className=''>
        <div className='text-nell-white tracking-[2.2px] font-black text-sm uppercase'>
          {screenPathname === 'Locations'
            ? translate('settingsScreen.locationInfo')
            : type === 'company'
            ? translate('settingsScreen.companyInfo')
            : translate('settingsScreen.locationInfo')}
        </div>
        <div className='mt-6'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 items-center'>
            <div className='flex flex-col gap-3'>
              <label htmlFor='legalName' className='text-sm font-bold'>
                {translate('settingsScreen.legalName')}*
              </label>
              <input
                type='text'
                name='legalName'
                id='legalName'
                value={companyProfile?.name || ''}
                onChange={e =>
                  setCompanyProfile({
                    ...companyProfile,
                    name: e.target.value,
                  })
                }
                className='tw-input'
                style={{ textAlign: 'left' }}
              />
            </div>

            <div className='flex flex-col gap-3'>
              <label htmlFor='operatingName' className='text-sm font-bold'>
                {translate('settingsScreen.operatingName')}
              </label>
              <input
                type='text'
                name='operatingName'
                id='operatingName'
                value={companyProfile?.profile?.info?.operatingName || ''}
                onChange={e =>
                  setCompanyProfile({
                    ...companyProfile,
                    profile: {
                      ...companyProfile?.profile,
                      info: {
                        ...companyProfile?.profile?.info,
                        operatingName: e.target.value,
                      },
                    },
                  })
                }
                className='tw-input'
                style={{ textAlign: 'left' }}
              />
            </div>

            <div className='relative flex flex-col gap-3'>
              <label htmlFor='website' className='text-sm font-bold'>
                {translate('settingsScreen.website')}
              </label>
              <input
                type='url'
                name='website'
                id='website'
                value={companyProfile?.profile?.info?.website || ''}
                onChange={e => {
                  const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
                  if (regex.test(e.target.value) || !e.target.value) {
                    setIsValidWebsite(true);
                  } else {
                    setIsValidWebsite(false);
                  }
                  setCompanyProfile({
                    ...companyProfile,
                    profile: {
                      ...companyProfile.profile,
                      info: {
                        ...companyProfile?.profile?.info,
                        website: e.target.value,
                      },
                    },
                  });
                }}
                className={`tw-input ${isValidWebsite ? '' : '!border-2 !border-primary-coral '}`}
                style={{ textAlign: 'left' }}
              />
              {!isValidWebsite && (
                <p className='absolute text-xs text-nell-red left-4 -bottom-5'>
                  {translate('settingsScreen.addValidWebsite')}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-3 lg:col-span-2'>
              <label htmlFor='bio' className='text-sm font-bold'>
                {translate('common.description')}*
              </label>
              <textarea
                name='bio'
                id='bio'
                value={companyProfile?.description || ''}
                onChange={e =>
                  setCompanyProfile({
                    ...companyProfile,
                    description: e.target.value,
                  })
                }
                className='tw-input min-h-[130px]'
                style={{ textAlign: 'left' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderAddress = () => {
    return (
      <div className=''>
        <div className='text-primary-leaf/50 tracking-[2.2px] font-black text-sm uppercase mb-4'>
          {translate('common.address')}
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 items-center'>
          <div className='flex flex-col gap-3'>
            <label htmlFor='address1' className='text-sm font-bold'>
              {translate('profileScreen.address1')}*
            </label>
            <input
              type='text'
              name='address1'
              id='address1'
              value={companyProfile?.address?.address1 || ''}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  address: {
                    ...companyProfile?.address,
                    address1: e.target.value,
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='address2' className='text-sm font-bold'>
              {translate('profileScreen.address2')}
            </label>
            <input
              type='text'
              name='address2'
              id='address2'
              value={companyProfile?.address?.address2 || ''}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  address: {
                    ...companyProfile?.address,
                    address2: e.target.value,
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='city' className='text-sm font-bold'>
              {translate('profileScreen.city')}*
            </label>
            <input
              type='text'
              name='city'
              id='city'
              value={companyProfile?.address?.city || ''}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  address: { ...companyProfile?.address, city: e.target.value },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>

          <div className='flex flex-col gap-3'>
            <label htmlFor='zip' className='text-sm font-bold'>
              {translate('profileScreen.postalCode')}
            </label>
            <input
              type='text'
              name='zip'
              id='zip'
              value={companyProfile?.address?.postalCode || ''}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  address: { ...companyProfile?.address, postalCode: e.target.value },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='country' className='text-sm font-bold'>
              {translate('profileScreen.country')}*
            </label>
            <CustomSelect
              options={countriesRegions.map((country: any) => ({
                label: country.countryName,
                value: country.countryName,
              }))}
              value={companyProfile?.address?.country || 'United States'}
              onChange={e => {
                setCompanyProfile({
                  ...companyProfile,
                  address: {
                    ...companyProfile?.address,
                    country: e?.value as string,
                    region: '',
                  },
                });
              }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='province' className='text-sm font-bold'>
              {companyProfile?.address?.country === 'United States'
                ? `${translate('profileScreen.state')}*`
                : companyProfile?.address?.country === 'Canada'
                ? `${translate('profileScreen.province')}*`
                : `${translate('profileScreen.region')}*`}
            </label>
            <CustomSelect
              options={
                countriesRegions
                  .find((c: any) => c.countryName === companyProfile?.address?.country)
                  ?.regions.map(
                    (region: any) => ({ label: region.name, value: region.name } || [])
                  ) || []
              }
              value={companyProfile?.address?.region || ''}
              onChange={e => {
                setCompanyProfile({
                  ...companyProfile,
                  address: { ...companyProfile?.address, region: e?.value as string },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const _renderContactInfo = () => {
    return (
      <div className=''>
        <div className='text-primary-leaf/50 tracking-[2.2px] font-black text-sm uppercase mb-4'>
          {translate('settingsScreen.contactPerson')}
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 items-center'>
          <div className='flex flex-col gap-3'>
            <label htmlFor='salutations' className='text-sm font-bold'>
              {translate('settingsScreen.salutations')}*
            </label>
            <CustomSelect
              options={[
                { label: 'Mr.', value: 'Mr.' },
                { label: 'Mrs.', value: 'Mrs.' },
                { label: 'Ms.', value: 'Ms.' },
                { label: 'Mx.', value: 'Mx.' },
                { label: 'Dr.', value: 'Dr.' },
                { label: 'Dre.', value: 'Dre.' },
              ]}
              value={companyProfile?.profile?.contactInfo?.salutation || 'Mr.'}
              onChange={(e: any) => {
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      salutation: e.value,
                    },
                  },
                });
              }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='firstName' className='text-sm font-bold'>
              {translate('profileScreen.firstName')}*
            </label>
            <input
              type='text'
              name='firstName'
              id='firstName'
              value={companyProfile?.profile?.contactInfo?.firstName || ''}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      firstName: e.target.value,
                    },
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='lastName' className='text-sm font-bold'>
              {translate('profileScreen.lastName')}*
            </label>
            <input
              type='text'
              name='lastName'
              id='lastName'
              value={companyProfile?.profile?.contactInfo?.lastName || ''}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      lastName: e.target.value,
                    },
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label htmlFor='title' className='text-sm font-bold'>
              {translate('settingsScreen.title')}*
            </label>
            <input
              type='text'
              name='title'
              id='title'
              value={companyProfile?.profile?.contactInfo?.title || ''}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      title: e.target.value,
                    },
                  },
                })
              }
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
          </div>
          <div className='relative flex flex-col gap-3'>
            <label htmlFor='phoneNumber' className='text-sm font-bold'>
              {translate('profileScreen.phoneNumber')}*
            </label>
            <div className='flex gap-2'>
              <span className='absolute top-[36px] sm:top-[38px] left-2 md:left-2 z-10'>
                <PhoneInput
                  country={'us'}
                  buttonStyle={{
                    background: '#171717',
                    border: 'none',
                    bottom: '2px',
                    top: '2px',
                    left: '2px',
                  }}
                  containerStyle={{
                    width: `${innerWidth < 640 ? '88px' : '88px'}`,
                    margin: 'auto',
                  }}
                  dropdownStyle={{ color: 'white', background: '#161919' }}
                  value={phoneCode}
                  onChange={(phone: any) => setPhoneCode(phone)}
                  placeholder={''}
                  inputProps={{
                    className:
                      'block w-full border-0 bg-transparent focus:ring-0 sm:text-sm text-right !pl-10 focus::!bg-black',
                  }}
                />
              </span>
              <input
                type='text'
                name='phone'
                id='phone'
                value={companyProfile?.profile?.contactInfo?.phone || ''}
                onChange={e => {
                  if (/^[0-9]+$/g.test(e.target.value) || !e.target.value)
                    setCompanyProfile({
                      ...companyProfile,
                      profile: {
                        ...companyProfile.profile,
                        contactInfo: {
                          ...companyProfile?.profile?.contactInfo,
                          phone: e.target.value,
                        },
                      },
                    });
                }}
                className={`tw-input !pl-24 md:!pl-28`}
                style={{ textAlign: 'left' }}
                placeholder={translate('profileScreen.phoneNumber')}
              />
            </div>
          </div>
          <div className='relative flex flex-col gap-3'>
            <label htmlFor='email2' className='text-sm font-bold'>
              {translate('common.email')}*
            </label>
            <input
              type='email'
              name='email2'
              id='email2'
              value={companyProfile?.profile?.contactInfo?.email || ''}
              onChange={e => {
                if (checkIsEmail(e.target.value)) {
                  setIsValidEmail(true);
                } else {
                  setIsValidEmail(false);
                }
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      email: e.target.value,
                    },
                  },
                });
              }}
              className='tw-input'
              style={{ textAlign: 'left' }}
            />
            {!isValidEmail && (
              <p className='absolute text-xs text-nell-red left-4 -bottom-5'>
                {translate('settingsScreen.addValidEmail')}
              </p>
            )}
          </div>
          <div className='flex flex-col gap-3'>
            <label className='text-sm font-bold'>
              {translate('settingsScreen.preferredContact')}*
            </label>
            <CustomRadio
              options={[
                { value: 'phone', label: 'Phone Number' },
                { value: 'email', label: 'Email' },
              ]}
              value={companyProfile?.profile?.contactInfo?.preferredContact || 'phone'}
              onChange={e => {
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      preferredContact: e?.value,
                    },
                  },
                });
              }}
            />
          </div>
          <div className='flex flex-col gap-3'>
            <label className='text-sm font-bold'>
              {translate('settingsScreen.preferredLanguage')}*
            </label>
            <CustomRadio
              options={[
                { value: 'english', label: 'English' },
                { value: 'french', label: 'French' },
              ]}
              value={companyProfile?.profile?.contactInfo?.preferredLanguage || 'english'}
              onChange={e =>
                setCompanyProfile({
                  ...companyProfile,
                  profile: {
                    ...companyProfile.profile,
                    contactInfo: {
                      ...companyProfile?.profile?.contactInfo,
                      preferredLanguage: e?.value,
                    },
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const _renderAcceptbutton = () => {
    return (
      <div className='flex justify-end m-5'>
        <button
          type='button'
          disabled={!isFormValid()}
          className='tw-button w-32'
          onClick={() => onAddUpdateProfile()}
        >
          {isLoading && <Spiner className='ml-24' />}
          {translate('common.save')}
        </button>
      </div>
    );
  };

  const _renderEditCreateOrganization = () => {
    return (
      <div className='flex flex-col gap-5 divide-y-2 divide-nell-mid-grey'>
        <div className='p-5'>{_renderInfo()}</div>
        <div className='p-5'>{_renderAddress()}</div>
        <div className='p-5'>{_renderContactInfo()}</div>
        <div>{_renderAcceptbutton()}</div>
      </div>
    );
  };

  return (
    <div className='relative sm:bg-nell-dark-grey text-nell-white sm:pt-2 mb-10 rounded-[20px] overflow-hidden'>
      {_renderEditCreateOrganization()}
    </div>
  );
};
